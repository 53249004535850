import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import { checkDUIValid, getISODateString, formattedGenderValue, getCountry, updateObjValue } from '../../utils/utils';
import { fieldEditStatus } from '../../config/UIConfig';
import {
  track,
  updateGlobalTracker,
  generateServerResponseError,
  generateMessageDisplayedSegment,
} from '../../utils/segmenTracker';
import {
  CAPTURE_ELEMENT_FRNTID,
  ACTIVATION,
  PHOTO_CAPTURE_COMPLETED,
  PHOTO_CAPTURE_FAILED,
  CAPTURE_ELEMENT_REAR,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
} from '../../constants/segmentEvents';

const initialState = {
  data: {
    actAttachment: [],
    issuingCountry: getCountry(),
  },
  documentType: '',
  ocrError: false,
  disableFields: { ...fieldEditStatus.idReview },
  duiStatus: { duiError: false, duiCheckInvoked: false },
  frontCount: 0,
  rearCount: 0,
};

export const idDetails = {
  state: { ...initialState },
  reducers: {
    updateFrontCount(state, payload) {
      return { ...state, frontCount: payload };
    },
    updateRearCount(state, payload) {
      return { ...state, rearCount: payload };
    },
    updateIdDetails(state, payload) {
      return { ...state, data: { ...state.data, ...payload } };
    },
    clearIdDetails() {
      return { ...initialState };
    },
    updateOCRError(state, payload) {
      return {
        ...state,
        ocrError: payload,
      };
    },
    updateDocumentType(state, payload) {
      return {
        ...state,
        documentType: payload,
      };
    },
    updateFieldEditStatus(state, payload) {
      return {
        ...state,
        disableFields: { ...state.disableFields, ...payload },
      };
    },
    resetEditFieldStatus(state) {
      return {
        ...state,
        disableFields: { ...initialState.disableFields },
      };
    },
    updateDUIStatus(state, payload) {
      return {
        ...state,
        duiStatus: { ...state.duiStatus, ...payload },
      };
    },
    clearDUIStatus(state) {
      return {
        ...state,
        duiStatus: { duiError: false, duiCheckInvoked: true },
      };
    },
    removeInfo(state) {
      return {
        data: {
          actAttachment: [],
          fileName: state.data.fileName,
        },
        documentType: '',
        ocrError: false,
        disableFields: { ...fieldEditStatus.idReview },
        duiStatus: { duiError: false, duiCheckInvoked: false },
      };
    },
  },
  effects: (dispatch) => {
    return {
      async uploadFront({ ...params }, state) {
        dispatch.idDetails.clearIdDetails();
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: 'post',
          url: svAPI.frontIdUpload,
          data: params.data,
        })
          .then((response) => {
            const {
              data: {
                Payload: { fileName, imageUrl },
              },
            } = response;
            const idDetails = {
              fileName: fileName.substring(0, fileName.lastIndexOf('/')),
              frontIDFileName: fileName,
              actAttachment: [
                {
                  action: 'A',
                  label: 'DUI_Front',
                  location: imageUrl,
                },
              ],
            };
            dispatch.idDetails.updateIdDetails({ ...idDetails });
            dispatch.apiStatus.setProcessing(false);
            params.callback && params.callback();
            const additionalData = {
              journey: ACTIVATION,
              capture_element: CAPTURE_ELEMENT_FRNTID,
            };
            updateGlobalTracker({ photo_capture_front: true, manual_input: false });
            track(PHOTO_CAPTURE_COMPLETED, additionalData);
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            const additionalData = {
              journey: ACTIVATION,
              capture_element: CAPTURE_ELEMENT_FRNTID,
              error_id: error.errorCode,
              error_message: error.message,
              error_type: 'error',
            };
            updateGlobalTracker({ photo_capture_front: true, manual_input: true });
            track(PHOTO_CAPTURE_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(
                error,
                ACTIVATION,
                'ID Scanning',
                svAPI.frontIdUpload,
                'Front ID Upload API'
              ),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(error, ACTIVATION, 'Front ID Upload API') });
          });
      },

      async getFrontIdDetails({ ...params }, state) {
        dispatch.apiStatus.setProcessing(true);
        const frontCount = state.idDetails.frontCount + 1;
        this.updateFrontCount(frontCount);
        this.updateRearCount(0);
        fetchApi({
          method: 'post',
          url: svAPI.rearIdRecognition,
          data: params.data,
        })
          .then((data) => {
            const {
              id_number = '',
              name = '',
              surname = '',
              dob = '',
              expiry_date = '',
              gender = '',
              fileName = '',
              imageUrl = '',
            } = data.data;
            const idDetails = {
              cliDUINumber: checkDUIValid(id_number),
              cliFirstName: name,
              cliLastName: surname,
              cliDob: getISODateString(dob),
              cliDocExpiry: getISODateString(expiry_date),
              cliGender: formattedGenderValue(gender),
              fileName: fileName.substring(0, fileName.lastIndexOf('/')),
              frontIDFileName: fileName,
              actAttachment: [
                {
                  action: 'A',
                  label: 'DUI_Front',
                  location: imageUrl,
                },
              ],
            };
            dispatch.idDetails.updateIdDetails({ ...idDetails });
            if (data?.data?.id_number) {
              dispatch.apiStatus.setProcessing(false);
              const additionalData = {
                journey: ACTIVATION,
                capture_element: CAPTURE_ELEMENT_FRNTID,
              };
              track(PHOTO_CAPTURE_COMPLETED, additionalData);
              this.updateFrontCount(0);
              params.callback && params.callback();
            } else {
              const additionalData = {
                journey: ACTIVATION,
                capture_element: CAPTURE_ELEMENT_FRNTID,
                error_id: 200,
                error_message: `No pudimos extraer los datos, por favor intenta nuevamente. Reintentar (${frontCount}/3)`,
                error_type: 'error',
              };
              track(PHOTO_CAPTURE_FAILED, additionalData);
              if (frontCount >= 3) {
                this.updateFrontCount(0);
                params.callback && params.callback();
              }
              dispatch.apiStatus.setRequestFailed({
                message: `No pudimos extraer los datos, por favor intenta nuevamente. Reintentar (${frontCount}/3)`,
              });
            }
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            const additionalData = {
              journey: ACTIVATION,
              capture_element: CAPTURE_ELEMENT_FRNTID,
              error_id: error.errorCode,
              error_message: error.message,
              error_type: 'error',
            };
            track(PHOTO_CAPTURE_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(
                error,
                ACTIVATION,
                'ID Scanning',
                svAPI.rearIdRecognition,
                'Front ID Upload API'
              ),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(error, ACTIVATION, 'Back ID Upload API') });
          });
      },

      async getRearIdDetails({ ...params }, state) {
        dispatch.apiStatus.setProcessing(true);
        dispatch.idDetails.resetEditFieldStatus();
        dispatch.idDetails.clearDUIStatus();
        this.updateFrontCount(0);
        const rearCount = state.idDetails.rearCount + 1;
        this.updateRearCount(rearCount);
        fetchApi({
          method: 'post',
          url: svAPI.rearIdRecognition,
          data: params.data,
        })
          .then((data) => {
            const filteredAttachmentList = state.idDetails.data.actAttachment.length
              ? state.idDetails.data.actAttachment.filter((i) => i.label !== 'DUI_Back')
              : [];
            const id_number = data?.data?.payload?.data?.id_number?.value || '';
            const profession = data?.data?.payload?.data?.profession?.value || '';
            const idDetails = {
              back_id: id_number,
              cliProfession: profession,
              actAttachment: [
                ...filteredAttachmentList,
                {
                  action: 'A',
                  label: 'DUI_Back',
                  location: data?.data?.imageUrl || '',
                },
              ],
            };
            dispatch.idDetails.updateIdDetails({ ...idDetails });
            if (data?.data?.payload?.data?.id_number?.value) {
              const val = levenshteinDistance(state.idDetails.data.cliDUINumber, id_number);
              if (val <= 2) {
                // dispatch.idDetails.updateFieldEditStatus({ ...updateObjValue(fieldEditStatus.idReview, true, state.idDetails.data) });
                dispatch.apiStatus.setProcessing(false);
                dispatch.idDetails.validateDUI(id_number);
                const additionalData = {
                  journey: ACTIVATION,
                  capture_element: CAPTURE_ELEMENT_REAR,
                };
                updateGlobalTracker({ photo_capture_rear: true, manual_input: false });
                track(PHOTO_CAPTURE_COMPLETED, additionalData);
                this.updateRearCount(0);
                params.callback && params.callback();
              } else {
                if (rearCount >= 3) {
                  updateGlobalTracker({ photo_capture_rear: true, manual_input: true });
                  dispatch.idDetails.updateOCRError(true);
                  this.updateRearCount(0);
                  params.callback && params.callback();
                }
                dispatch.apiStatus.setRequestFailed({
                  message: `El número del DUI de la parte de atras no concuerda con la parte frontal. Reintentar (${rearCount}/3)`,
                });
              }
            } else {
              const additionalData = {
                journey: ACTIVATION,
                capture_element: CAPTURE_ELEMENT_REAR,
                error_id: 200,
                error_message: `No pudimos extraer los datos, por favor intenta nuevamente. Reintentar (${rearCount}/3)`,
                error_type: 'error',
              };
              track(PHOTO_CAPTURE_FAILED, additionalData);
              if (rearCount >= 3) {
                updateGlobalTracker({ photo_capture_rear: true, manual_input: true });
                dispatch.idDetails.updateOCRError(true);
                this.updateRearCount(0);
                params.callback && params.callback();
              }
              dispatch.apiStatus.setRequestFailed({
                message: `No pudimos extraer los datos, por favor intenta nuevamente. Reintentar (${rearCount}/3)`,
              });
            }
          })
          .catch((error) => {
            updateGlobalTracker({ photo_capture_rear: true, manual_input: true });
            dispatch.apiStatus.setRequestFailed({ ...error });
            const additionalData = {
              journey: ACTIVATION,
              capture_element: CAPTURE_ELEMENT_REAR,
              error_id: error.errorCode,
              error_message: error.message,
              error_type: 'error',
            };
            track(PHOTO_CAPTURE_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(
                error,
                ACTIVATION,
                'ID Scanning',
                svAPI.rearIdRecognition,
                'Back ID Upload API'
              ),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(error, ACTIVATION, 'Back ID Upload API') });
          });
      },
      async validateDUI(id) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'post',
          url: svAPI.validateDUI.replace('{}', id),
        })
          .then((data) => {
            dispatch.apiStatus.setProcessing(false);
            const { eligibility_result, eligibility_decisions } = data.data;
            if (eligibility_result === 'accepted') {
              dispatch.idDetails.updateDUIStatus({ duiError: false, duiCheckInvoked: true });
              updateGlobalTracker({ id_validation: true });
            } else {
              dispatch.idDetails.updateDUIStatus({ duiError: true, duiCheckInvoked: true });
              updateGlobalTracker({ id_validation: false });
              dispatch.apiStatus.setRequestFailed({
                errorCode: '',
                message: eligibility_decisions?.[0]?.description,
              });
            }
            return eligibility_result;
          })
          .catch((err) => {
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {
              ...generateServerResponseError(
                err,
                ACTIVATION,
                'ID Scanning',
                svAPI.rearIdRecognition,
                'Validate ID API'
              ),
            });
            track(MESSAGE_DISPLAYED, { ...generateMessageDisplayedSegment(err, ACTIVATION, 'Validate ID API') });
            return new Error(err);
          });
      },
    };
  },
};

const levenshteinDistance = (str1 = '', str2 = '') => {
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      );
    }
  }
  return track[str2.length][str1.length];
};
