export const HN_MUNCIPALITIES =  [
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "AGUANQUETERIQUE"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "AHUAS"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "AJUTERIQUE"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "ALAUCA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "ALIANZA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "ALUBAREN"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "AMAPALA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "APACILAGUA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "ARADA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "ARAMECINA"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "ARENAL"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "ARIZONA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "ATIMA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "AZACUALPA"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "BALFATE"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "BELEN"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "BELEN GUALCHO"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "BONITO ORIENTAL"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "BRUS LAGUNA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "CABAÑAS"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CABAÑAS"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "CAMASCA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "CAMPAMENTO"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CANDELARIA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "CANE"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "CARIDAD"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CATACAMAS"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CEDROS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "CEGUACA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "CHINACLA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "CHINDA"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "CHOLOMA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "CHOLUTECA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "COLOLACA"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "COLOMONCAGUA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "COMAYAGUA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CONCEPCION"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "CONCEPCION"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CONCEPCION"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "CONCEPCION DE MARIA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "CONCEPCION DEL NORTE"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "CONCEPCION DEL SUR"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "CONCORDIA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "COPAN RUINAS"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "CORQUIN"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "CUCUYAGUA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "CURAREN"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "DANLI"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "DISTRITO CENTRAL"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "DOLORES"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "DOLORES"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "DOLORES MERENDON"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "DULCE NOMBRE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "DULCE NOMBRE DE CULMI"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "DUYURE"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "EL CORPUS"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "EL NEGRITO"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "EL NISPERO"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "EL PARAISO"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "EL PARAISO"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "EL PORVENIR"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "EL PORVENIR"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "EL PROGRESO"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "EL ROSARIO"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "EL ROSARIO"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "EL TRIUNFO"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "ERANDIQUE"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "ESPARTA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "ESQUIAS"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "ESQUIPULAS DEL NORTE"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "FLORIDA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "FRATERNIDAD"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "GOASCORAN"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "GRACIAS"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "GUAIMACA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "GUAJIQUIRO"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "GUALACO"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "GUALALA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "GUALCINCE"
    },
    {
        "CODIGO_DEPTO": "011",
        "DEPARTAMENTO": "ISLAS DE LA BAHIA",
        "CODIGO_MUNICIPIO": "002",
        "MUNICIPIO": "GUANAJA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "GUARITA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "GUARIZAMA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "GUATA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "GUAYAPE"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "GÜINOPE"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "HUMUYA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "ILAMA"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "INTIBUCA"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "IRIONA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "JACALEAPA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "JANO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "JESUS DE OTORO"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "JOCON"
    },
    {
        "CODIGO_DEPTO": "011",
        "DEPARTAMENTO": "ISLAS DE LA BAHIA",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "JOSE SANTOS GUARDIOLA"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "JUAN FRANCISCO BULNES"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "JUTIAPA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "JUTICALPA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "LA CAMPA"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "LA CEIBA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "LA ENCARNACION"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "LA ESPERANZA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "LA IGUALA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "LA JIGUA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LA LABOR"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "LA LIBERTAD"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LA LIBERTAD"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "LA LIMA"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "LA MASICA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "LA PAZ"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "LA TRINIDAD"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "LA UNION"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "LA UNION"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "LA UNION"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "LA VENTA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "LA VIRTUD"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LAMANI"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LANGUE"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "LAS FLORES"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "LAS LAJAS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "027",
        "MUNICIPIO": "LAS VEGAS"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LAUTERIQUE"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "LEJAMANI"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "LEPAERA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "LEPATERIQUE"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "LIMON"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "LIURE"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "LUCERNA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "MACUELIZO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "MAGDALENA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "MANGULILE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "MANTO"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "MAPULACA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "MARAITA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "MARALE"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "MARCALA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "MARCOVIA"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "MASAGUARA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "MEAMBAR"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "MERCEDES"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "MERCEDES DE ORIENTE"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "MINAS DE ORO"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "MORAZAN"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "MOROCELI"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "MOROLICA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "NACAOME"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "NAMASIGUE"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "NARANJITO"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "NUEVA ARCADIA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "NUEVA ARMENIA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "028",
        "MUNICIPIO": "NUEVA FRONTERA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "NUEVO CELILAC"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "OCOTEPEQUE"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "OJOJONA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "OJOS DE AGUA"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "OLANCHITO"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "003",
        "MUNICIPIO": "OMOA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "OPATORO"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "ORICA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "OROCUINA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "OROPOLI"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "023",
        "MUNICIPIO": "PATUCA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "PESPIRE"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "PETOA"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "PIMIENTA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "PIRAERA"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "POTRERILLOS"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "POTRERILLOS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "PROTECCION"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "PUERTO CORTES"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "PUERTO LEMPIRA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "QUIMISTAN"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "REITOCA"
    },
    {
        "CODIGO_DEPTO": "011",
        "DEPARTAMENTO": "ISLAS DE LA BAHIA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "ROATAN"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "SABA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SABANAGRANDE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SALAMA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SAN AGUSTIN"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SAN ANDRES"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SAN ANTONIO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "SAN ANTONIO"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "SAN ANTONIO DE CORTES"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "SAN ANTONIO DE FLORES"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "SAN ANTONIO DE FLORES"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN ANTONIO DE ORIENTE"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "SAN ANTONIO DEL NORTE"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SAN BUENAVENTURA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN ESTEBAN"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "SAN FERNANDO"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "SAN FRANCISCO"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN FRANCISCO"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SAN FRANCISCO DE BECERRA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "SAN FRANCISCO DE CORAY"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "SAN FRANCISCO DE LA PAZ"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SAN FRANCISCO DE OJUERA"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN FRANCISCO DE OPALACA"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "SAN FRANCISCO DE YOJOA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "SAN FRANCISCO DEL VALLE"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "SAN IGNACIO"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN ISIDRO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "SAN ISIDRO"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN JERONIMO"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SAN JERONIMO"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "SAN JORGE"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SAN JOSE"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN JOSE"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "SAN JOSE"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "SAN JOSE DE COLINAS"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SAN JOSE DE COMAYAGUA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SAN JOSE DEL POTRERO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "SAN JUAN"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN JUAN"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "SAN JUAN DE FLORES"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SAN JUAN DE OPOA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SAN JUAN GUARITA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "SAN LORENZO"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "012",
        "MUNICIPIO": "SAN LUCAS"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SAN LUIS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "SAN LUIS"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "SAN MANUEL"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "SAN MANUEL COLOHETE"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN MARCOS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "SAN MARCOS"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "028",
        "MUNICIPIO": "SAN MARCOS DE CAIQUIN"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SAN MARCOS DE COLON"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN MARCOS DE LA SIERRA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "013",
        "MUNICIPIO": "SAN MATIAS"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "SAN MIGUELITO"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SAN MIGUELITO"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "SAN NICOLAS"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "022",
        "MUNICIPIO": "SAN NICOLAS"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "SAN PEDRO DE COPAN"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SAN PEDRO DE TUTULE"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "SAN PEDRO SULA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "023",
        "MUNICIPIO": "SAN PEDRO ZACAPA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "SAN RAFAEL"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SAN SEBASTIAN"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "SAN SEBASTIAN"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "024",
        "MUNICIPIO": "SAN VICENTE CENTENARIO"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "022",
        "MUNICIPIO": "SANTA ANA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SANTA ANA"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SANTA ANA DE YUSGUARE"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "SANTA BARBARA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "022",
        "MUNICIPIO": "SANTA CRUZ"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "SANTA CRUZ DE YOJOA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SANTA ELENA"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "SANTA FE"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SANTA FE"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "023",
        "MUNICIPIO": "SANTA LUCIA"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SANTA LUCIA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "SANTA MARIA"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "020",
        "MUNICIPIO": "SANTA MARIA DEL REAL"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "SANTA RITA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "025",
        "MUNICIPIO": "SANTA RITA"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "SANTA RITA"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "SANTA ROSA DE AGUAN"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "SANTA ROSA DE COPAN"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SANTIAGO DE PURINGLA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "SENSENTI"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "SIGUATEPEQUE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "SILCA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "SINUAPA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "014",
        "MUNICIPIO": "SOLEDAD"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "008",
        "MUNICIPIO": "SONAGUERA"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "SULACO"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "024",
        "MUNICIPIO": "TALANGA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "023",
        "MUNICIPIO": "TALGUA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "024",
        "MUNICIPIO": "TAMBLA"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "025",
        "MUNICIPIO": "TATUMBLA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "021",
        "MUNICIPIO": "TAULABE"
    },
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA",
        "CODIGO_MUNICIPIO": "007",
        "MUNICIPIO": "TELA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "015",
        "MUNICIPIO": "TEUPASENTI"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "TEXIGUAT"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "009",
        "MUNICIPIO": "TOCOA"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "025",
        "MUNICIPIO": "TOMALA"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA",
        "CODIGO_MUNICIPIO": "026",
        "MUNICIPIO": "TRINIDAD"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "022",
        "MUNICIPIO": "TRINIDAD DE COPAN"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "TROJES"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "TRUJILLO"
    },
    {
        "CODIGO_DEPTO": "011",
        "DEPARTAMENTO": "ISLAS DE LA BAHIA",
        "CODIGO_MUNICIPIO": "004",
        "MUNICIPIO": "UTILA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "017",
        "MUNICIPIO": "VADO ANCHO"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "026",
        "MUNICIPIO": "VALLADOLID"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "026",
        "MUNICIPIO": "VALLE DE ANGELES"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "028",
        "MUNICIPIO": "VALLECILLO"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN",
        "CODIGO_MUNICIPIO": "023",
        "MUNICIPIO": "VERACRUZ"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "010",
        "MUNICIPIO": "VICTORIA"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "VILLA DE SAN ANTONIO"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN",
        "CODIGO_MUNICIPIO": "027",
        "MUNICIPIO": "VILLA DE SAN FRANCISCO"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "VILLANUEVA"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "005",
        "MUNICIPIO": "VILLEDA MORALES"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA",
        "CODIGO_MUNICIPIO": "027",
        "MUNICIPIO": "VIRGINIA"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS",
        "CODIGO_MUNICIPIO": "006",
        "MUNICIPIO": "WAMPUSIRPI"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA",
        "CODIGO_MUNICIPIO": "016",
        "MUNICIPIO": "YAMARANGUILA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ",
        "CODIGO_MUNICIPIO": "019",
        "MUNICIPIO": "YARULA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "018",
        "MUNICIPIO": "YAUYUPE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO",
        "CODIGO_MUNICIPIO": "022",
        "MUNICIPIO": "YOCON"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "011",
        "MUNICIPIO": "YORITO"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "YORO"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO",
        "CODIGO_MUNICIPIO": "001",
        "MUNICIPIO": "YUSCARAN"
    }
]