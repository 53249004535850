import React from 'react';
import MaskedInput from 'react-text-mask';

function MaskInput(props) {
  const { inputRef, mask, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={mask}
    />
  );
}

export default MaskInput;
