import styled from 'styled-components';
import { appColors } from 'constants/colors';
import Dialog from '@material-ui/core/Dialog';

export const Modal = styled(Dialog)`
.MuiPaper-root {
  width: 100%;
  max-width: 707px;
}
`;

export const ModalWrp = styled.div`
  .MuiPaper-root {
    width: 100%;
    max-width: 707px;
  }
`;
export const Content = styled.div`
  display: flex;
  button {
    padding: 0 18px;
    min-height: 36px;
    line-height: 36px;
    margin: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  position: relative;
  background-color: ${appColors.textColorDarkBlue};
  color: ${appColors.appColors};
  flex-direction: column;
  padding: 23px;
  align-items: center;
  flex: 1;
  max-width: 50%;
  button {
    margin-top: 33px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  .closeIcon {
    position: absolute;
    right: 17px;
    top: 17px;
    cursor: pointer;
    height: 13px;
    @media (min-width: 1024px) {
      height: 18px;
    }
  }
`;

export const Banner = styled.div`
  width: 120px;
  position: absolute;
  height: 00%;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-left: 18px solid ${appColors.textColorDarkBlue};
  background: ${appColors.yellow};
  right: 0;
  top: 0;
  box-sizing: border-box;
`;

export const Heading = styled.span`
  font-weight: 500;
  font-size: 16.625px;
  line-height: 19px;
  color: ${appColors.textColorDarkBlue};
  padding-bottom: 19px;
`;

export const Info = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${appColors.textColorGrey};
`;

export const Link = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${appColors.secondaryCyan};
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  img {
    width: 16px;
    margin-right: 6px;
  }
`;

export const RefLinks = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${appColors.textColorGrey};
  .link {
    color: ${appColors.secondaryCyan};
    display: block;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 29px 15px;
  button:disabled,
  button[disabled] {
    border: 1px solid #f1f2f3;
    background-color: #f1f2f3;
    color: #9d9fa1;
  }
  button {
    margin: 0;
    min-width: 132px;
  }
`;
export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e9e9e9;
  padding: 29px;
`;

export const InputWrp = styled.div`
  > div {
    margin: 0;
    min-width: 293px;
  }
  > div:first-child {
    padding-bottom: 15px;
  }
  padding-top: 18px;
  padding-bottom: 18px;
`;
export const BtnWrp = styled.div`
  position: relative;
  margin: 13px 0;
  opacity: ${({ isProcessing }) => isProcessing && '0.5'};
  .MuiCircularProgress-root {
    position: absolute;
    z-index: 10;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
  .MuiPaper-root {
    color: #00c8ff;
  }
`;
