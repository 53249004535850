const UAParser = require('ua-parser-js/dist/ua-parser.min');

const UA = new UAParser();

export const getDevice = () => {
  const { vendor, model, type } = UA.getDevice();
  const formattedType = type === 'mobile' ? 'mobile web' : 'web';
  return { vendor, model, type: formattedType };
};

export const getOS = () => {
  const { name, version } = UA.getOS();
  return { name, version };
};

export const isIOSDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
