import * as Aes from 'crypto-js/aes';
import * as CryptoJsCore from 'crypto-js/core';
class SecureStorage {
  setItem = (key, data) => {
    data = Aes.encrypt(JSON.stringify(data), process.env.REACT_APP_ENC_HELPER);
    data = data.toString();
    localStorage.setItem(key, data);
  };
  getItem = (key) => {
    let cacheString = localStorage.getItem(key);
    if (!cacheString) {
      return {};
    }
    try {
      cacheString = Aes.decrypt(cacheString, process.env.REACT_APP_ENC_HELPER);
      cacheString = cacheString.toString(CryptoJsCore.enc.Utf8);
      return JSON.parse(cacheString) || {};
    } catch (err) {
      console.log(err);
      return {};
    }
  };
  removeItem = (key) => {
    localStorage.removeItem(key);
  };
}

export default new SecureStorage();
