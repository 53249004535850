

import { configHN } from "config/UIConfig_HN";
import { pageURL_HN , pageURLs } from  '../../constants/URLs';



const HN_ROUTES  = {
    appRoutes: [{
      path: '/',
      component: 'Landing',
    },
    {
      path: '/redirect',
      component: 'Landing',
    },
    {
      path: pageURL_HN.clientInformation,
      component: 'ClientInformation',
      formData: configHN['ClientInformation']
    },
    {
      path: pageURLs.maintenanace,
      component: 'Maintenance'
    },
  ],
    privateAppRoutes: [
      {
        path: '/',
        component: 'HomePage',
        formData: configHN['HomePage']
      },
      {
        path: pageURL_HN.home,
        component: 'HomePage',
        formData: configHN['HomePage']
      },
      {
        path: pageURL_HN.activeTransaction,
        component: 'ActiveTransaction',
        formData: configHN['ActiveTransaction']
      },
      {
        path: pageURL_HN.existingServices,
        component: 'ExistingServices',
        formData: configHN['ExistingServices']
      },
      {
        path: pageURL_HN.mitigoRedirection,
        component: 'MitigoRedirection',
        formData: configHN['MitigoRedirection']
      },
      {
        path: pageURL_HN.locationServices,
        component: 'LocationActivation',
        formData: configHN['LocationActivation']
      },
      {
        path: pageURL_HN.inputAddress,
        component: 'InputAddress',
        formData: configHN['InputAddress']
      },
      {
        path: pageURL_HN.map,
        component: 'LocationService',
        formData: configHN['LocationService']
      },
      {
        path: pageURL_HN.offerNotAvailable,
        component: 'NoOffer',
        formData: configHN['NoOffer']
      },
      {
        path: pageURL_HN.products,
        component: 'ProductSelection',
        formData: configHN['ProductSelection']
      },
      {
        path: pageURL_HN.addons,
        component: 'Addons',
        formData: configHN['Addons']
      },
      {
        path: pageURL_HN.idScanFront,
        component: 'IDFront',
        formData: configHN['IDFront']
      },
      {
        path: pageURL_HN.idReview,
        component: 'IDReview',
        formData: configHN['IDReview']
      },
      {
        path: pageURL_HN.checkout,
        component: 'Checkout',
        formData: configHN['Checkout']
      },
      {
        path: pageURL_HN.idNotValid,
        component: 'NoOffer',
        formData: configHN['IdNotValid']
      },
      {
        path: pageURL_HN.contractInfo,
        component: 'ContractInfo',
        formData: configHN['ContractInfo']
      },
      {
        path: pageURL_HN.verifyPhone,
        component: 'VerifyOTP',
        formData: configHN['VerifyPhone']

      },
      {
        path: pageURL_HN.verifyEmail,
        component: 'VerifyOTP',
        formData: configHN['VerifyEmail']

      },
      {
        path: pageURL_HN.activation,
        component: 'Activation',
        formData: configHN['Activation']

      },
      {
        path: pageURL_HN.orderSuccess,
        component: 'OrderSuccess',
        formData: configHN['OrderSuccess']

      },
      {
        path: pageURL_HN.salesReport,
        component: 'SalesReport',
        formData: configHN['SalesReport']
      },
      {
        path: pageURL_HN.scheduleVisit,
        component: 'ScheduleOrder',
        formData: configHN['ScheduleVisit']
      },
      {
        path: pageURL_HN.clientInfoFailed,
        component: 'ClientInfoFailed'
      }
    ]
}

  export default HN_ROUTES;