import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import themeConstant from './constants/themeConstant';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { loadHotjar, hotjarCountries } from 'utils/loadHotjar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

if (process.env.REACT_APP_COUNTRY === 'CO') {
  document.title = 'Contrata tu plan Hogar - Tigo Colombia';
} else {
  document.title = 'Home Activator - Tigo';
}

if (process.env.REACT_APP_COUNTRY === 'NI') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TAG_MANAGER_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

if (hotjarCountries.includes(process.env.REACT_APP_COUNTRY)) {
  loadHotjar();
}

ReactDOM.render(
  <MuiThemeProvider theme={themeConstant}>
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'es'}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <App />
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (!['NI', 'CO'].includes(process.env.REACT_APP_COUNTRY)) {
  serviceWorker.register();
}
