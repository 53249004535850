
import { inputAddress } from './inputAddress';
import { auth } from './auth';
import { apiStatus } from './apiStatus';
import { packages } from './packages';
import { sales } from './sales';
import { clientDetails } from './clientDetails';
import { activation } from './activation';
import { scheduleVisit } from './scheduleVisit';
import { idDetails } from './idDetails';
import { documentUpload } from './documentUpload';
import { registration } from './registration';
import { termsAndConditions } from './termsAndConditions';
import { captureStatus } from './captureStatus'
import { location } from './location'

export default {
  inputAddress,
  auth,
  apiStatus,
  packages,
  sales,
  clientDetails,
  activation,
  scheduleVisit,
  idDetails,
  documentUpload,
  registration,
  termsAndConditions,
  captureStatus,
  location
};