import { appColors } from './colors'
import { dimensions } from './dimensions';
import { imageURLs } from './assets'
import { texts, HN_TEXTS, CO_TEXTS, GT_TEXTS, PY_TEXTS, NI_TEXTS, CR_TEXTS, SV_TEXTS } from './texts';
import { config } from './config';
import { pageURLs, pageURL_HN, pageURL_GT, pageURL_CO, pageURL_PY, pageURL_NI, pageURL_CR, pageURL_BO } from './URLs';
import { REGEX_CONSTANTS } from './regex';
import { HN_CANCEL_REASONS } from './hn/miselanious';
import {HN_MUNCIPALITIES} from './hn/muncips_HN';
import {HN_DEPARTMENTS} from './hn/departments_HN';

export { 
    appColors, 
    dimensions, 
    imageURLs, 
    texts, 
    config, 
    pageURLs, 
    pageURL_HN,
    pageURL_CO,
    pageURL_CR,
    pageURL_NI,
    HN_TEXTS, 
    HN_DEPARTMENTS, 
    HN_MUNCIPALITIES, 
    HN_CANCEL_REASONS,
    CO_TEXTS,
    SV_TEXTS,
    REGEX_CONSTANTS,
    pageURL_GT,
    GT_TEXTS,
    NI_TEXTS,
    PY_TEXTS,
    pageURL_PY,
    pageURL_BO,
    CR_TEXTS
}