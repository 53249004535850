import { pageURL_PY } from 'constants/URLs';
import { configPY } from '../UIConfig_PY';

export default {
  appRoutes: [
    {
      path: '/',
      component: 'Landing',
    },
    {
      path: '/redirect',
      component: 'Landing',
    },
  ],
  privateAppRoutes: [
    {
      path: '/',
      component: 'HomePage',
      formData: configPY['HomePage'],
    },
    { path: pageURL_PY.idScanRear, component: 'IDRear' },
    {
      path: pageURL_PY.confirmOrder,
      component: 'ConfirmOrder',
      formData: configPY['ConfirmOrder'],
    },
    {
      path: pageURL_PY.home,
      component: 'HomePage',
      formData: configPY['HomePage'],
    },
    {
      path: pageURL_PY.clientInformation,
      component: 'ClientInformation',
      formData: configPY['ClientInformation'],
    },
    {
      path: pageURL_PY.error,
      component: 'ValidationError',
      formData: configPY['ValidationError'],
    },
    {
      path: pageURL_PY.inputAddress,
      component: 'AddressForm',
    },
    {
      path: pageURL_PY.products,
      component: 'ProductSelection',
    },
    {
      path: pageURL_PY.addons,
      component: 'Addons',
    },
    { path: pageURL_PY.checkout, component: 'Checkout' },
    {
      path: pageURL_PY.idScanFront,
      component: 'IDFront',
    },
    {
      path: pageURL_PY.idReview,
      component: 'IDReview',
    },
    {
      path: pageURL_PY.orderSuccess,
      component: 'OrderSuccess',
    },
    {
      path: pageURL_PY.orderFailed,
      component: 'OrderFailed',
    },
    {
      path: pageURL_PY.orderFail,
      component: 'OrderFail'
    },
    {
      path: pageURL_PY.idNotValid,
      component: 'InvalidID',
    },
    {
      path: pageURL_PY.verifyPhone,
      component: 'VerifyOTP',
    },
    { path: pageURL_PY.verifyEmail, component: 'VerifyEmail' },
    {
      path: pageURL_PY.salesReport,
      component: 'SalesReport',
    },
    {
      path: pageURL_PY.transactionInProgress,
      component: 'TransactionInProgress',
    },
    {
      path: pageURL_PY.salesManagement,
      component: 'SalesManagement',
    },
    {
      path: pageURL_PY.contractInfo,
      component: 'Contract',
    },
    {
      path: pageURL_PY.LocationActivation,
      component: 'LocationActivation'
    }
  ],
}