import styled from 'styled-components';
import { appColors } from 'constants/colors';


export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Image = styled.div`
  display: flex;
  margin-right: 5px;
`;

export const Link = styled.div`
  display: flex;
  color: #FFFFFF;
  cursor: pointer;
`;
