import React, { Suspense, lazy, useEffect, useRef } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Loader } from 'tigo-components';
import { routes } from './config/routes';
import './App.css';
import { getCookie, getCountry } from './utils/utils';
import { LocationLogger } from './components/locationLogger';
import { pageURL_GT, pageURL_HN } from './constants';
import ScrollTop from 'components/scrollTop';
import { pageURL_CR, pageURL_BO } from 'constants/URLs';
import { brazeService } from 'utils/brazeService';
import Contentful from 'pages/co/Contentful';
import { loadGTM } from 'utils/loadGTM';

const country = getCountry().toLowerCase();

const mouseFlow = () => {
  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement('script');
    mf.type = 'text/javascript';
    mf.defer = true;
    mf.src = '//cdn.mouseflow.com/projects/22ba0e6d-63f6-40c7-815b-10d2f83479da.js';
    document.getElementsByTagName('head')[0].appendChild(mf);
  })();
};

if (process.env.REACT_APP_GTM_ENABLED === 'TRUE') {
  loadGTM();
}

if (process.env.REACT_APP_COUNTRY === 'CO') {
  mouseFlow();
}

const PublicRoute = ({ component, ...rest }) => {
  const AsyncComponent = lazy(() => import(`./pages/${country}/${component}`));
  return <Route {...rest} render={(props) => <AsyncComponent {...props} {...rest} />} />;
};

const PrivateRoute = ({ component, ...rest }) => {
  const AsyncComponent = lazy(() => import(`./pages/${country}/${component}`));
  return (
    <Route
      {...rest}
      render={(props) => {
        return getCookie() ? <AsyncComponent {...props} {...rest} /> : <Redirect to="/" />;
      }}
    />
  );
};

function App({ history }) {
  const currentPathname = useRef(null);
  const country = getCountry();
  useEffect(() => {
    brazeService.initializeBraze();
    if (country === 'HN') {
      history.listen((newLocation, action) => {
        if (action === 'PUSH') {
          if (newLocation.pathname !== currentPathname) {
            currentPathname.current = newLocation.pathname;
          }
        } else {
          if (
            currentPathname.current === pageURL_HN.scheduleVisit ||
            currentPathname.current === pageURL_HN.orderSuccess
          ) {
            history.push(currentPathname.current);
          } else {
            currentPathname.current = newLocation.pathname;
          }
        }
      });
    }
    if (country === 'CO') {
      history.listen((newLocation, action) => {
        if (action === 'PUSH') {
          if (newLocation.pathname !== currentPathname) {
            currentPathname.current = newLocation.pathname;
          }
        } else {
          if (currentPathname.current === '/order-success') {
            history.push('/');
          } else {
            currentPathname.current = newLocation.pathname;
          }
        }
      });
    }
    if (country === 'GT') {
      history.listen((newLocation, action) => {
        if (action === 'PUSH') {
          if (newLocation.pathname !== currentPathname) {
            currentPathname.current = newLocation.pathname;
          }
        } else {
          if (currentPathname.current === pageURL_GT.orderSuccess) {
            history.push('/');
          } else {
            currentPathname.current = newLocation.pathname;
          }
        }
      });
    }
    if (country === 'CR') {
      if (history.location.pathname === '/') {
        history.replace(pageURL_CR.clientInformation);
      }
      history.listen((newLocation, action) => {
        if (newLocation.pathname === '/') {
          history.go(-1 * history.length);
          setTimeout(() => {
            history.replace(pageURL_CR.clientInformation);
          }, 10);
        }
      });
    }
    if (country === 'BO') {
      if (history.location.pathname === '/') {
        history.replace(pageURL_BO.clientInformation);
      }
      history.listen((newLocation, action) => {
        if (newLocation.pathname === '/') {
          history.go(-1 * history.length);
          setTimeout(() => {
            history.replace(pageURL_BO.clientInformation);
          }, 10);
        }
      });
    }
  }, []);
  return (
    <div className="App">
      <Suspense fallback={<Loader open={true} />}>
        <LastLocationProvider>
          <ScrollTop />
          <Switch>
            {routes[country].appRoutes.map((r, i) => (
              <PublicRoute exact key={`public-route-${i}`} path={r.path} component={r.component} {...r} />
            ))}
            {routes[country].privateAppRoutes.map((r, i) => (
              <PrivateRoute key={`private-route-${i}`} exact path={r.path} component={r.component} {...r} />
            ))}
          </Switch>
          <LocationLogger />
        </LastLocationProvider>
        {process.env.REACT_APP_COUNTRY === 'CO' && <Contentful />}
      </Suspense>
    </div>
  );
}

export default withRouter(App);
