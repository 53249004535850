import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import { 
  track,  
  generateServerResponseError, 
  generateMessageDisplayedSegment,  
} from '../../utils/segmenTracker';
import { visitType} from '../../utils/utils';
import {
  SCHEDULE_VISIT_FAILED,
  ACTIVATION,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";
import { texts } from '../../constants/texts';
import moment from 'moment'

const initialState = {
  data: {
    orderType: 'Instalation',
    workingDate: 0,
  },
  suggestedActivationDates: [],
};

export const scheduleVisit = {
  state: { ...initialState },
  reducers: {
    updateSchedule (state, payload) {
      return { ...state, data: { ...state.data, ...payload } };
    },
    updateActivationDate (state, payload) {
      return { ...state, suggestedActivationDates: payload };
    },
    clearSchedule(state) {
      return { ...state, suggestedActivationDates: [] }
    }
  },
  effects: dispatch => {
    return {
      async getScheduleInfo (data) {
        this.clearSchedule()
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: 'post',
          url: svAPI.scheduleDates,
          data: data,
        })
          .then(response => {
            dispatch.scheduleVisit.updateActivationDate(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.errorCode === 404 ? texts.dateAvailablityError : error?.message?.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error, message: error.errorCode === 404 ? texts.dateAvailablityError :  error?.message });
            }
            const additionalData = {
              error_id: error.errorCode,
              error_message: error.message, 
              journey: ACTIVATION,
              service : '',
              visit_type: visitType(data.orderType),
            }
            track(SCHEDULE_VISIT_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Schedule Visit', svAPI.scheduleDates, 'Schedule Info API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Schedule Info API')});
          });
      },

      async getScheduleDates (data) {
        this.clearSchedule()
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: 'post',
          url : svAPI.getTimeSlots + encodeURIComponent(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')),
          data : data
        })
          .then(response => {
            dispatch.scheduleVisit.updateActivationDate(response.data.OptionalAppointments);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.errorCode === 404 ? texts.dateAvailablityError : error?.message?.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error, message: error.errorCode === 404 ? texts.dateAvailablityError :  error?.message });
            }
            const additionalData = {
              error_id: error.errorCode,
              error_message: error.message, 
              journey: ACTIVATION,
              service : '',
              visit_type: visitType(data.orderType),
            }
            track(SCHEDULE_VISIT_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Schedule Visit', svAPI.scheduleDates, 'Schedule Info API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Schedule Info API')});
          });
      },

    };
  },
};
