import fetchApi from "../../services/fetch";
import { svAPI } from "../../constants/api";
import { 
  track, 
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
 } from '../../utils/segmenTracker';
import {
  ACTIVATION,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";

const initialState = {
  data: []
};

export const termsAndConditions = {
  state: { ...initialState },
  reducers: {
    saveData(state, payload) {
        return {
            ...state,
            data: payload
        }
    },
    clearTerms() {
      return { ...initialState }
    }
  },

  effects: dispatch => {
    return {
      async getTermsAndConditions() {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "get",
          url: svAPI.termsAndConditions,
        })
          .then(response => {
            const { data: { items }} = response;
            const filteredItems = items.map(i => i.fields);
            dispatch.termsAndConditions.saveData(filteredItems);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Terms and Conditions', svAPI.termsAndConditions, 'Terms and Conditions API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Terms and Conditions API')})
          });
      },
    };
  }
};