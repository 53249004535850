export const GT_PAISEEMISSION = [
    {
        "CODE": "",
        "DESC": "",
        "SHORTDESC": ""
    },
    {
        "CODE": 1001,
        "DESC": "ANTIGUA Y BARBUDA",
        "SHORTDESC": "ABA"
    },
    {
        "CODE": 1002,
        "DESC": "AFGANISTAN",
        "SHORTDESC": "AFG"
    },
    {
        "CODE": 1003,
        "DESC": "ALBANIA",
        "SHORTDESC": "ALB"
    },
    {
        "CODE": 1004,
        "DESC": "ALGERIA",
        "SHORTDESC": "ALG"
    },
    {
        "CODE": 1005,
        "DESC": "AMERICAN SOMAO",
        "SHORTDESC": "AMS"
    },
    {
        "CODE": 1006,
        "DESC": "ANGOLA",
        "SHORTDESC": "AN"
    },
    {
        "CODE": 1007,
        "DESC": "ANDORRA",
        "SHORTDESC": "AND"
    },
    {
        "CODE": 1008,
        "DESC": "ANGUILLA",
        "SHORTDESC": "ANG"
    },
    {
        "CODE": 1009,
        "DESC": "ANTARTICA (SCOTT) Via NZ TNZI",
        "SHORTDESC": "ANS"
    },
    {
        "CODE": 1010,
        "DESC": "ANTARTICA (CASEY) Via AU Telstra",
        "SHORTDESC": "ANT"
    },
    {
        "CODE": 1011,
        "DESC": "ARGENTINA",
        "SHORTDESC": "ARG"
    },
    {
        "CODE": 1012,
        "DESC": "ARMENIA",
        "SHORTDESC": "ARM"
    },
    {
        "CODE": 1013,
        "DESC": "ARUBA",
        "SHORTDESC": "ARU"
    },
    {
        "CODE": 1014,
        "DESC": "ASCENCION ISLAND via BT",
        "SHORTDESC": "ASI"
    },
    {
        "CODE": 1015,
        "DESC": "AUSTRIA",
        "SHORTDESC": "AU"
    },
    {
        "CODE": 1016,
        "DESC": "AUSTRALIA",
        "SHORTDESC": "AUS"
    },
    {
        "CODE": 1017,
        "DESC": "ALTO VOLTA",
        "SHORTDESC": "AVL"
    },
    {
        "CODE": 1018,
        "DESC": "AZERBAIJAN",
        "SHORTDESC": "AZB"
    },
    {
        "CODE": 1019,
        "DESC": "AZORES",
        "SHORTDESC": "AZO"
    },
    {
        "CODE": 1020,
        "DESC": "BAHAMAS",
        "SHORTDESC": "BAA"
    },
    {
        "CODE": 1021,
        "DESC": "BAHRAIN",
        "SHORTDESC": "BAH"
    },
    {
        "CODE": 1022,
        "DESC": "BANGLADESH",
        "SHORTDESC": "BAN"
    },
    {
        "CODE": 1023,
        "DESC": "BARBADOS",
        "SHORTDESC": "BAR"
    },
    {
        "CODE": 1024,
        "DESC": "BELGIUM",
        "SHORTDESC": "BE"
    },
    {
        "CODE": 1025,
        "DESC": "BELICE",
        "SHORTDESC": "BEL"
    },
    {
        "CODE": 1026,
        "DESC": "BENIN",
        "SHORTDESC": "BEN"
    },
    {
        "CODE": 1027,
        "DESC": "BERMUDA",
        "SHORTDESC": "BER"
    },
    {
        "CODE": 1028,
        "DESC": "BHUTAN via UK BT",
        "SHORTDESC": "BHU"
    },
    {
        "CODE": 1029,
        "DESC": "BELARUS",
        "SHORTDESC": "BLA"
    },
    {
        "CODE": 1030,
        "DESC": "BOSNIA-HERCEGOVINA",
        "SHORTDESC": "BOH"
    },
    {
        "CODE": 1031,
        "DESC": "BOLIVIA",
        "SHORTDESC": "BOL"
    },
    {
        "CODE": 1032,
        "DESC": "BORNEO",
        "SHORTDESC": "BOR"
    },
    {
        "CODE": 1033,
        "DESC": "BOTSWANA",
        "SHORTDESC": "BOW"
    },
    {
        "CODE": 1034,
        "DESC": "BRAZIL",
        "SHORTDESC": "BRA"
    },
    {
        "CODE": 1035,
        "DESC": "BRUNEI - DST",
        "SHORTDESC": "BRU"
    },
    {
        "CODE": 1036,
        "DESC": "BURUNDI via FR",
        "SHORTDESC": "BUD"
    },
    {
        "CODE": 1037,
        "DESC": "BULGARIA",
        "SHORTDESC": "BUL"
    },
    {
        "CODE": 1038,
        "DESC": "BURKINA FASO",
        "SHORTDESC": "BUR"
    },
    {
        "CODE": 1039,
        "DESC": "BURMA",
        "SHORTDESC": "BRM"
    },
    {
        "CODE": 1040,
        "DESC": "BRITISH VIRGIN ISLAND",
        "SHORTDESC": "BVI"
    },
    {
        "CODE": 1041,
        "DESC": "CENTRAL AFRICAN REPUBLIC via FR",
        "SHORTDESC": "CAF"
    },
    {
        "CODE": 1042,
        "DESC": "CAMBODIA",
        "SHORTDESC": "CAM"
    },
    {
        "CODE": 1043,
        "DESC": "CANADA",
        "SHORTDESC": "CAN"
    },
    {
        "CODE": 1044,
        "DESC": "CHRISTMANS & COCOS ISLANDS (via AU Telst",
        "SHORTDESC": "CCI"
    },
    {
        "CODE": 1045,
        "DESC": "CHAD via FR",
        "SHORTDESC": "CHA"
    },
    {
        "CODE": 1046,
        "DESC": "CHILE",
        "SHORTDESC": "CHI"
    },
    {
        "CODE": 1047,
        "DESC": "CHINA PEOPEL'S, REPUBLIC OF",
        "SHORTDESC": "CHR"
    },
    {
        "CODE": 1048,
        "DESC": "CAIMAN ISLANDS",
        "SHORTDESC": "CIS"
    },
    {
        "CODE": 1049,
        "DESC": "CAMEROON",
        "SHORTDESC": "CMR"
    },
    {
        "CODE": 1050,
        "DESC": "CONGO",
        "SHORTDESC": "CNG"
    },
    {
        "CODE": 1051,
        "DESC": "COOK ISLANDS (via TNZ)",
        "SHORTDESC": "COI"
    },
    {
        "CODE": 1052,
        "DESC": "COLOMBIA",
        "SHORTDESC": "COL"
    },
    {
        "CODE": 1053,
        "DESC": "COMOROS via FR",
        "SHORTDESC": "COM"
    },
    {
        "CODE": 1054,
        "DESC": "CONGO, DEMOCRATIC REPUBLIC OF - Afritel",
        "SHORTDESC": "CON"
    },
    {
        "CODE": 1055,
        "DESC": "COSTA RICA",
        "SHORTDESC": "CR"
    },
    {
        "CODE": 1056,
        "DESC": "CROATIA",
        "SHORTDESC": "CRO"
    },
    {
        "CODE": 1057,
        "DESC": "CUBA (Excluding Guantanamo)",
        "SHORTDESC": "CUB"
    },
    {
        "CODE": 1058,
        "DESC": "CUBA (GUANTANAMO)",
        "SHORTDESC": "CUG"
    },
    {
        "CODE": 1059,
        "DESC": "CAPE VERDE ISLANDS",
        "SHORTDESC": "CVI"
    },
    {
        "CODE": 1060,
        "DESC": "CYPRUS",
        "SHORTDESC": "CYP"
    },
    {
        "CODE": 1061,
        "DESC": "CZECH REPUBLIC",
        "SHORTDESC": "CZR"
    },
    {
        "CODE": 1062,
        "DESC": "DENMARK",
        "SHORTDESC": "DEN"
    },
    {
        "CODE": 1063,
        "DESC": "DIEGO GARCIA",
        "SHORTDESC": "DGA"
    },
    {
        "CODE": 1064,
        "DESC": "DJIBOUTI REPULBIC OF",
        "SHORTDESC": "DJI"
    },
    {
        "CODE": 1065,
        "DESC": "DOMINICA",
        "SHORTDESC": "DOM"
    },
    {
        "CODE": 1066,
        "DESC": "DOMINICAN REPUBLIC",
        "SHORTDESC": "DOR"
    },
    {
        "CODE": 1067,
        "DESC": "ECUADOR",
        "SHORTDESC": "ECU"
    },
    {
        "CODE": 1068,
        "DESC": "ECUATORIAL GUINEA",
        "SHORTDESC": "EGI"
    },
    {
        "CODE": 1069,
        "DESC": "EGYPT,  ARAB REPUBLIC OF",
        "SHORTDESC": "EGY"
    },
    {
        "CODE": 1070,
        "DESC": "EL SALVADOR",
        "SHORTDESC": "ELS"
    },
    {
        "CODE": 1071,
        "DESC": "ERITREA",
        "SHORTDESC": "ERI"
    },
    {
        "CODE": 1072,
        "DESC": "ESTONIA",
        "SHORTDESC": "EST"
    },
    {
        "CODE": 1073,
        "DESC": "ETHIOPIA",
        "SHORTDESC": "ETH"
    },
    {
        "CODE": 1074,
        "DESC": "FALKLAND ISLAND (via UK BT)",
        "SHORTDESC": "FAI"
    },
    {
        "CODE": 1075,
        "DESC": "FRENCH ANTILLES (Guad, Martinique, St.Ba",
        "SHORTDESC": "FAN"
    },
    {
        "CODE": 1076,
        "DESC": "FEAROE ISLAND (via Denmark)",
        "SHORTDESC": "FEA"
    },
    {
        "CODE": 1077,
        "DESC": "FRENCH GUIANA",
        "SHORTDESC": "FGU"
    },
    {
        "CODE": 1078,
        "DESC": "FIJI ISLANDS",
        "SHORTDESC": "FIJ"
    },
    {
        "CODE": 1079,
        "DESC": "FINLAND -Telecom",
        "SHORTDESC": "FIN"
    },
    {
        "CODE": 1080,
        "DESC": "FRENCH POLYNESIA",
        "SHORTDESC": "FPO"
    },
    {
        "CODE": 1081,
        "DESC": "FRANCE",
        "SHORTDESC": "FRA"
    },
    {
        "CODE": 1082,
        "DESC": "GABONESE REPUBLIC",
        "SHORTDESC": "GAB"
    },
    {
        "CODE": 1083,
        "DESC": "GAMBIA",
        "SHORTDESC": "GAM"
    },
    {
        "CODE": 1084,
        "DESC": "GEORGIA",
        "SHORTDESC": "GEO"
    },
    {
        "CODE": 1085,
        "DESC": "GERMANY",
        "SHORTDESC": "GER"
    },
    {
        "CODE": 1086,
        "DESC": "GHANA",
        "SHORTDESC": "GHA"
    },
    {
        "CODE": 1087,
        "DESC": "GIBRALTAR",
        "SHORTDESC": "GIB"
    },
    {
        "CODE": 1088,
        "DESC": "GUINEA, PEOPLES REPUBLIC OF",
        "SHORTDESC": "GIP"
    },
    {
        "CODE": 1089,
        "DESC": "GRENADA",
        "SHORTDESC": "GRA"
    },
    {
        "CODE": 1090,
        "DESC": "GREECE",
        "SHORTDESC": "GRE"
    },
    {
        "CODE": 1091,
        "DESC": "GROENLANDIA",
        "SHORTDESC": "GRN"
    },
    {
        "CODE": 1092,
        "DESC": "GUATEMALA",
        "SHORTDESC": "GUA"
    },
    {
        "CODE": 1093,
        "DESC": "GUINEA BISSAU (via PO CPRM)",
        "SHORTDESC": "GUB"
    },
    {
        "CODE": 1094,
        "DESC": "GUADALUPE",
        "SHORTDESC": "GUD"
    },
    {
        "CODE": 1095,
        "DESC": "GUYANA FRANCESA",
        "SHORTDESC": "GUF"
    },
    {
        "CODE": 1096,
        "DESC": "GUINEA, PEOPLES REPUBLICS OF - Spacetel",
        "SHORTDESC": "GUI"
    },
    {
        "CODE": 1097,
        "DESC": "GUAM IT&E",
        "SHORTDESC": "GUM"
    },
    {
        "CODE": 1098,
        "DESC": "GUYANA",
        "SHORTDESC": "GUY"
    },
    {
        "CODE": 1099,
        "DESC": "HAITI",
        "SHORTDESC": "HAI"
    },
    {
        "CODE": 1100,
        "DESC": "HONG KONG",
        "SHORTDESC": "HOK"
    },
    {
        "CODE": 1101,
        "DESC": "HONDURAS",
        "SHORTDESC": "HON"
    },
    {
        "CODE": 1102,
        "DESC": "HUNGARY",
        "SHORTDESC": "HUN"
    },
    {
        "CODE": 1103,
        "DESC": "ICELAND",
        "SHORTDESC": "ICE"
    },
    {
        "CODE": 1104,
        "DESC": "INDONESIA Indosat",
        "SHORTDESC": "IDN"
    },
    {
        "CODE": 1105,
        "DESC": "IRIDIUM - ICAM - PREMIUM",
        "SHORTDESC": "IIP"
    },
    {
        "CODE": 1106,
        "DESC": "INDONESIA SatelIndo",
        "SHORTDESC": "IN"
    },
    {
        "CODE": 1107,
        "DESC": "INMARSAT A",
        "SHORTDESC": "INA"
    },
    {
        "CODE": 1108,
        "DESC": "INMARSAT B",
        "SHORTDESC": "INB"
    },
    {
        "CODE": 1109,
        "DESC": "INDIA",
        "SHORTDESC": "IND"
    },
    {
        "CODE": 1110,
        "DESC": "INMARSAT M",
        "SHORTDESC": "INM"
    },
    {
        "CODE": 1111,
        "DESC": "IRELAND",
        "SHORTDESC": "IRE"
    },
    {
        "CODE": 1112,
        "DESC": "IRIDIUM - ICAM",
        "SHORTDESC": "IRI"
    },
    {
        "CODE": 1113,
        "DESC": "IRAN",
        "SHORTDESC": "IRN"
    },
    {
        "CODE": 1114,
        "DESC": "IRAQ",
        "SHORTDESC": "IRQ"
    },
    {
        "CODE": 1115,
        "DESC": "ISRAEL - Bezeq",
        "SHORTDESC": "ISB"
    },
    {
        "CODE": 1116,
        "DESC": "ISRAEL - Bezeq",
        "SHORTDESC": "ISR"
    },
    {
        "CODE": 1117,
        "DESC": "ITALY",
        "SHORTDESC": "ITA"
    },
    {
        "CODE": 1118,
        "DESC": "IVORY COAST",
        "SHORTDESC": "IVO"
    },
    {
        "CODE": 1119,
        "DESC": "JAMAICA",
        "SHORTDESC": "JAM"
    },
    {
        "CODE": 1120,
        "DESC": "JAPAN",
        "SHORTDESC": "JAP"
    },
    {
        "CODE": 1121,
        "DESC": "JORDAN",
        "SHORTDESC": "JOR"
    },
    {
        "CODE": 1122,
        "DESC": "KAZAKHSTAN",
        "SHORTDESC": "KAZ"
    },
    {
        "CODE": 1123,
        "DESC": "KENYA",
        "SHORTDESC": "KEN"
    },
    {
        "CODE": 1124,
        "DESC": "KIRIBATI (GILBERT ISLANDS) via AU Telstr",
        "SHORTDESC": "KIR"
    },
    {
        "CODE": 1125,
        "DESC": "KOREA NOTH",
        "SHORTDESC": "KON"
    },
    {
        "CODE": 1126,
        "DESC": "KOREA SOUTH - Korea Telecom",
        "SHORTDESC": "KOS"
    },
    {
        "CODE": 1127,
        "DESC": "KUWAIT",
        "SHORTDESC": "KUW"
    },
    {
        "CODE": 1128,
        "DESC": "LAOS (via AU Telstra)",
        "SHORTDESC": "LAO"
    },
    {
        "CODE": 1129,
        "DESC": "LATVIA",
        "SHORTDESC": "LAT"
    },
    {
        "CODE": 1130,
        "DESC": "LEBANON",
        "SHORTDESC": "LEB"
    },
    {
        "CODE": 1131,
        "DESC": "LESOTHO, KINDOM OF",
        "SHORTDESC": "LES"
    },
    {
        "CODE": 1132,
        "DESC": "LIBERIA",
        "SHORTDESC": "LIB"
    },
    {
        "CODE": 1133,
        "DESC": "LIECHTSTENSTEIN (Via Swizerland)",
        "SHORTDESC": "LIE"
    },
    {
        "CODE": 1134,
        "DESC": "LITHUANIA Omnitel",
        "SHORTDESC": "LIO"
    },
    {
        "CODE": 1135,
        "DESC": "LITHUANIA Telecom",
        "SHORTDESC": "LIT"
    },
    {
        "CODE": 1136,
        "DESC": "LUXEMBOURG",
        "SHORTDESC": "LUX"
    },
    {
        "CODE": 1137,
        "DESC": "LIBYA",
        "SHORTDESC": "LYB"
    },
    {
        "CODE": 1138,
        "DESC": "MACEDONIA",
        "SHORTDESC": "MA"
    },
    {
        "CODE": 1139,
        "DESC": "MALAYSIA - CELCOM",
        "SHORTDESC": "MAC"
    },
    {
        "CODE": 1140,
        "DESC": "MARSHALL ISLAND",
        "SHORTDESC": "MAI"
    },
    {
        "CODE": 1141,
        "DESC": "MARIANAS",
        "SHORTDESC": "MRN"
    },
    {
        "CODE": 1142,
        "DESC": "MALTA",
        "SHORTDESC": "MAL"
    },
    {
        "CODE": 1143,
        "DESC": "MALAYSIA - MAESAT",
        "SHORTDESC": "MAM"
    },
    {
        "CODE": 1144,
        "DESC": "MALDIVES, REPUBLICS OF",
        "SHORTDESC": "MAR"
    },
    {
        "CODE": 1145,
        "DESC": "MALAYSIA - Telecom",
        "SHORTDESC": "MAT"
    },
    {
        "CODE": 1146,
        "DESC": "MAURITANIA",
        "SHORTDESC": "MAU"
    },
    {
        "CODE": 1147,
        "DESC": "MAYOTE ISLAND via FR",
        "SHORTDESC": "MAY"
    },
    {
        "CODE": 1148,
        "DESC": "MACAU",
        "SHORTDESC": "MCA"
    },
    {
        "CODE": 1149,
        "DESC": "MADAGASCAR, DEM REP OF (via FR)",
        "SHORTDESC": "MDG"
    },
    {
        "CODE": 1150,
        "DESC": "MEXICO",
        "SHORTDESC": "MEX"
    },
    {
        "CODE": 1151,
        "DESC": "MICRONESIA, FED STATES",
        "SHORTDESC": "MIC"
    },
    {
        "CODE": 1152,
        "DESC": "MALI",
        "SHORTDESC": "MLI"
    },
    {
        "CODE": 1153,
        "DESC": "MALAWI",
        "SHORTDESC": "MLW"
    },
    {
        "CODE": 1154,
        "DESC": "MOLDOVIA (via Teleglove)",
        "SHORTDESC": "MOL"
    },
    {
        "CODE": 1155,
        "DESC": "MONACO",
        "SHORTDESC": "MON"
    },
    {
        "CODE": 1156,
        "DESC": "MOROCCO",
        "SHORTDESC": "MOR"
    },
    {
        "CODE": 1157,
        "DESC": "MOZAMBIQUE",
        "SHORTDESC": "MOZ"
    },
    {
        "CODE": 1158,
        "DESC": "MONGOLIAN PEOPLE'S REPUBLICS",
        "SHORTDESC": "MPR"
    },
    {
        "CODE": 1159,
        "DESC": "MAURICIO",
        "SHORTDESC": "MRC"
    },
    {
        "CODE": 1160,
        "DESC": "MARTINICA",
        "SHORTDESC": "MTQ"
    },
    {
        "CODE": 1161,
        "DESC": "MONTSERRAT",
        "SHORTDESC": "MTS"
    },
    {
        "CODE": 1162,
        "DESC": "MAURITIUS",
        "SHORTDESC": "MUR"
    },
    {
        "CODE": 1163,
        "DESC": "MYANMAR (BURMA) via Hong Kong",
        "SHORTDESC": "MYM"
    },
    {
        "CODE": 1164,
        "DESC": "NAMIBIA",
        "SHORTDESC": "NAM"
    },
    {
        "CODE": 1165,
        "DESC": "NETHERLANDS ANTILLES (Bonaire,Curacaso,S",
        "SHORTDESC": "NAN"
    },
    {
        "CODE": 1166,
        "DESC": "NAURU",
        "SHORTDESC": "NAU"
    },
    {
        "CODE": 1167,
        "DESC": "NO DEFINIDO",
        "SHORTDESC": "NDF"
    },
    {
        "CODE": 1168,
        "DESC": "NETHERLANDS",
        "SHORTDESC": "NE"
    },
    {
        "CODE": 1169,
        "DESC": "NEPAL",
        "SHORTDESC": "NEP"
    },
    {
        "CODE": 1170,
        "DESC": "NETHERLANDS - Telefort",
        "SHORTDESC": "NET"
    },
    {
        "CODE": 1171,
        "DESC": "NUEVA HEBRIDAS",
        "SHORTDESC": "NHB"
    },
    {
        "CODE": 1172,
        "DESC": "NICARAGUA",
        "SHORTDESC": "NIC"
    },
    {
        "CODE": 1173,
        "DESC": "NIGERIA",
        "SHORTDESC": "NIG"
    },
    {
        "CODE": 1174,
        "DESC": "NIGER, REPUBLIC OF",
        "SHORTDESC": "NIR"
    },
    {
        "CODE": 1175,
        "DESC": "NIUE ISLAND (via NZ TNZI)",
        "SHORTDESC": "NIU"
    },
    {
        "CODE": 1176,
        "DESC": "NORFOLK ISLAND (via AU Telstra)",
        "SHORTDESC": "NOI"
    },
    {
        "CODE": 1177,
        "DESC": "NORWAY",
        "SHORTDESC": "NOR"
    },
    {
        "CODE": 1178,
        "DESC": "NEW  CALEDONIA",
        "SHORTDESC": "NWC"
    },
    {
        "CODE": 1179,
        "DESC": "NEW ZEALAND (Tokleau Is)  - Clear",
        "SHORTDESC": "NZE"
    },
    {
        "CODE": 1180,
        "DESC": "OMAN",
        "SHORTDESC": "OMA"
    },
    {
        "CODE": 1181,
        "DESC": "PAKISTAN Off Peak",
        "SHORTDESC": "PAK"
    },
    {
        "CODE": 1182,
        "DESC": "PALAU, REPUBLIC OF",
        "SHORTDESC": "PAL"
    },
    {
        "CODE": 1183,
        "DESC": "PANAMA",
        "SHORTDESC": "PAN"
    },
    {
        "CODE": 1184,
        "DESC": "PARAGUAY",
        "SHORTDESC": "PAR"
    },
    {
        "CODE": 1185,
        "DESC": "PERU",
        "SHORTDESC": "PER"
    },
    {
        "CODE": 1186,
        "DESC": "PHILIPPINES - PLDT",
        "SHORTDESC": "PHI"
    },
    {
        "CODE": 1187,
        "DESC": "PHILIPPINES - PLDT",
        "SHORTDESC": "PHP"
    },
    {
        "CODE": 1188,
        "DESC": "PAKISTAN (blended)",
        "SHORTDESC": "PKB"
    },
    {
        "CODE": 1189,
        "DESC": "PAKISTAN Peak",
        "SHORTDESC": "PKP"
    },
    {
        "CODE": 1190,
        "DESC": "PAPUA NEW GUINEA",
        "SHORTDESC": "PNG"
    },
    {
        "CODE": 1191,
        "DESC": "POLONIA",
        "SHORTDESC": "POL"
    },
    {
        "CODE": 1192,
        "DESC": "PORTUGAL",
        "SHORTDESC": "POR"
    },
    {
        "CODE": 1193,
        "DESC": "QATAR",
        "SHORTDESC": "QAT"
    },
    {
        "CODE": 1194,
        "DESC": "RUSSIA - ASTELIT",
        "SHORTDESC": "RAS"
    },
    {
        "CODE": 1195,
        "DESC": "RUSSIA - BALTIC COM.",
        "SHORTDESC": "RBC"
    },
    {
        "CODE": 1196,
        "DESC": "RUSSIA - COMBELLGA",
        "SHORTDESC": "RCB"
    },
    {
        "CODE": 1197,
        "DESC": "RUSSIA - COMSTAR",
        "SHORTDESC": "RCS"
    },
    {
        "CODE": 1198,
        "DESC": "REUNION ISLAND (via Mauritius)",
        "SHORTDESC": "REI"
    },
    {
        "CODE": 1199,
        "DESC": "RUSSIA - NALHODKA",
        "SHORTDESC": "RNH"
    },
    {
        "CODE": 1200,
        "DESC": "ROMANIA",
        "SHORTDESC": "ROM"
    },
    {
        "CODE": 1201,
        "DESC": "RUSSIA - SAKHALIN Island",
        "SHORTDESC": "RSH"
    },
    {
        "CODE": 1202,
        "DESC": "RUSSIA - SOVINTEL",
        "SHORTDESC": "RSI"
    },
    {
        "CODE": 1203,
        "DESC": "SURINAME, REPUBLIC OF",
        "SHORTDESC": "RSU"
    },
    {
        "CODE": 1204,
        "DESC": "RUSSIA - TELEPORT",
        "SHORTDESC": "RTP"
    },
    {
        "CODE": 1205,
        "DESC": "RUSSIA - TATARSTAN (via Italy)",
        "SHORTDESC": "RTT"
    },
    {
        "CODE": 1206,
        "DESC": "RUSSIA - ROSTELECOM (Excludes 7+5XX or o",
        "SHORTDESC": "RUR"
    },
    {
        "CODE": 1207,
        "DESC": "RUSIA",
        "SHORTDESC": "RUS"
    },
    {
        "CODE": 1208,
        "DESC": "RWANDA (via FR)",
        "SHORTDESC": "RWA"
    },
    {
        "CODE": 1209,
        "DESC": "SAUDI ARABIA Blended",
        "SHORTDESC": "SAB"
    },
    {
        "CODE": 1210,
        "DESC": "SOUTH AFRICA (inc. Bophuthatwswana)",
        "SHORTDESC": "SAF"
    },
    {
        "CODE": 1211,
        "DESC": "SAIPAN (Mariana Island)",
        "SHORTDESC": "SAI"
    },
    {
        "CODE": 1212,
        "DESC": "SAUDI ARABIA Off - Peak",
        "SHORTDESC": "SAO"
    },
    {
        "CODE": 1213,
        "DESC": "SAUDI ARABIA Peak",
        "SHORTDESC": "SAP"
    },
    {
        "CODE": 1214,
        "DESC": "SYRIAN ARAB, REPUBLIC",
        "SHORTDESC": "SAR"
    },
    {
        "CODE": 1215,
        "DESC": "SEYCHELLES ISALND (via UK BT)",
        "SHORTDESC": "SEI"
    },
    {
        "CODE": 1216,
        "DESC": "SENEGAL, REPUBLIC OF",
        "SHORTDESC": "SEN"
    },
    {
        "CODE": 1217,
        "DESC": "SERBIA /old Yugoslavia / Includes Monten",
        "SHORTDESC": "SER"
    },
    {
        "CODE": 1218,
        "DESC": "ST. HELENA (Includes tristan de Cunha) v",
        "SHORTDESC": "SHE"
    },
    {
        "CODE": 1219,
        "DESC": "SINGAPORE",
        "SHORTDESC": "SIN"
    },
    {
        "CODE": 1220,
        "DESC": "ST. KITTS & NEVIS",
        "SHORTDESC": "SKI"
    },
    {
        "CODE": 1221,
        "DESC": "SIERRA LEONE",
        "SHORTDESC": "SLE"
    },
    {
        "CODE": 1222,
        "DESC": "ST. LUCIA",
        "SHORTDESC": "SLU"
    },
    {
        "CODE": 1223,
        "DESC": "SLOVAKIA",
        "SHORTDESC": "SLV"
    },
    {
        "CODE": 1224,
        "DESC": "SAN MARINO (via Italy)",
        "SHORTDESC": "SMA"
    },
    {
        "CODE": 1225,
        "DESC": "SOMALIA - city code 112,113,114,32,82 (v",
        "SHORTDESC": "SMC"
    },
    {
        "CODE": 1226,
        "DESC": "SOMALIA - city code 2 thru 9 (via Italy)",
        "SHORTDESC": "SMI"
    },
    {
        "CODE": 1227,
        "DESC": "SALOMON",
        "SHORTDESC": "SMN"
    },
    {
        "CODE": 1228,
        "DESC": "SAMAO",
        "SHORTDESC": "SMO"
    },
    {
        "CODE": 1229,
        "DESC": "SOLOMON ISLANDS",
        "SHORTDESC": "SOI"
    },
    {
        "CODE": 1230,
        "DESC": "SOMAILA - city code 115, 116 (Barakaat)",
        "SHORTDESC": "SOM"
    },
    {
        "CODE": 1231,
        "DESC": "SPAIN (Includes Canary Island)",
        "SHORTDESC": "SPA"
    },
    {
        "CODE": 1232,
        "DESC": "ST.PIERRE & MIQUELON (via FR)",
        "SHORTDESC": "SPM"
    },
    {
        "CODE": 1233,
        "DESC": "SRI LANKA",
        "SHORTDESC": "SRI"
    },
    {
        "CODE": 1234,
        "DESC": "SAO TOME & PRINCIPE (via PO CPRM)",
        "SHORTDESC": "STP"
    },
    {
        "CODE": 1235,
        "DESC": "SUDAN, REPUBLIC OF",
        "SHORTDESC": "SUD"
    },
    {
        "CODE": 1236,
        "DESC": "SURINAME",
        "SHORTDESC": "SUR"
    },
    {
        "CODE": 1237,
        "DESC": "SLOVENIA",
        "SHORTDESC": "SVE"
    },
    {
        "CODE": 1238,
        "DESC": "SWEDEN -Tele2 - Telia",
        "SHORTDESC": "SWE"
    },
    {
        "CODE": 1239,
        "DESC": "SWITZERLAND",
        "SHORTDESC": "SWI"
    },
    {
        "CODE": 1240,
        "DESC": "SWAZILAND",
        "SHORTDESC": "SWZ"
    },
    {
        "CODE": 1241,
        "DESC": "TAIWAN",
        "SHORTDESC": "TAI"
    },
    {
        "CODE": 1242,
        "DESC": "TAJISKISTAN",
        "SHORTDESC": "TAJ"
    },
    {
        "CODE": 1243,
        "DESC": "TANZANIA",
        "SHORTDESC": "TAN"
    },
    {
        "CODE": 1244,
        "DESC": "TURKS & CAICOS ISLAND",
        "SHORTDESC": "TCI"
    },
    {
        "CODE": 1245,
        "DESC": "THAILAND",
        "SHORTDESC": "THA"
    },
    {
        "CODE": 1246,
        "DESC": "TIMOR",
        "SHORTDESC": "TMP"
    },
    {
        "CODE": 1247,
        "DESC": "TOGO",
        "SHORTDESC": "TOG"
    },
    {
        "CODE": 1248,
        "DESC": "TONGA ISLANDS",
        "SHORTDESC": "TON"
    },
    {
        "CODE": 1249,
        "DESC": "TRINIDAD & TOBAGO",
        "SHORTDESC": "TRT"
    },
    {
        "CODE": 1250,
        "DESC": "TURKMENISTAN",
        "SHORTDESC": "TUM"
    },
    {
        "CODE": 1251,
        "DESC": "TUNISIA",
        "SHORTDESC": "TUN"
    },
    {
        "CODE": 1252,
        "DESC": "TURKEY",
        "SHORTDESC": "TUR"
    },
    {
        "CODE": 1253,
        "DESC": "TUVALU",
        "SHORTDESC": "TVL"
    },
    {
        "CODE": 1254,
        "DESC": "UNITED ARAB EMIRATES",
        "SHORTDESC": "UAE"
    },
    {
        "CODE": 1255,
        "DESC": "UGAND",
        "SHORTDESC": "UGA"
    },
    {
        "CODE": 1256,
        "DESC": "UNITED KINGDOM",
        "SHORTDESC": "UKI"
    },
    {
        "CODE": 1257,
        "DESC": "UKRANIE",
        "SHORTDESC": "UKR"
    },
    {
        "CODE": 1258,
        "DESC": "URUGUAY",
        "SHORTDESC": "URU"
    },
    {
        "CODE": 1259,
        "DESC": "UNITED STATES OF AMERICA",
        "SHORTDESC": "USA"
    },
    {
        "CODE": 1260,
        "DESC": "UZBEKISTAN",
        "SHORTDESC": "UZB"
    },
    {
        "CODE": 1261,
        "DESC": "VATICAN CITY (via Italy)",
        "SHORTDESC": "VAC"
    },
    {
        "CODE": 1262,
        "DESC": "VANUATU (via Optus)",
        "SHORTDESC": "VAN"
    },
    {
        "CODE": 1263,
        "DESC": "VENEZUELA",
        "SHORTDESC": "VEN"
    },
    {
        "CODE": 1264,
        "DESC": "VIETNAM, REPUBLIC OF SOUTH",
        "SHORTDESC": "VNS"
    },
    {
        "CODE": 1265,
        "DESC": "WAKE",
        "SHORTDESC": "WAK"
    },
    {
        "CODE": 1266,
        "DESC": "WALLIS & FORTUNE ISLAND (via New Caledon",
        "SHORTDESC": "WFI"
    },
    {
        "CODE": 1267,
        "DESC": "WESTERN SOMAO",
        "SHORTDESC": "WSO"
    },
    {
        "CODE": 1268,
        "DESC": "YEMEN, ARAB REPUBLIC",
        "SHORTDESC": "YEM"
    },
    {
        "CODE": 1269,
        "DESC": "YUGOSLAVIA",
        "SHORTDESC": "YUG"
    },
    {
        "CODE": 1270,
        "DESC": "ZAMBIA",
        "SHORTDESC": "ZAM"
    },
    {
        "CODE": 1271,
        "DESC": "ZIMBABWE",
        "SHORTDESC": "ZIM"
    },
    {
        "CODE": 1272,
        "DESC": "ZAIRE",
        "SHORTDESC": "ZIR"
    }
]