import {
    isMobile
  } from '../../utils/utils';
  
const initialState = {
    data: {
        isUpload: isMobile ? false : true
    }
  }
  
  export const captureStatus = {
    state: { ...initialState },
    reducers: {
      updateCaptureStatus (state, payload) {
        return {
          ...state,
          data: { ...state.data, isUpload: payload }
        }
      },
      clearCaptureStatus() {
        return {
          ...initialState
        }
      }
    }
  }
  