import { REGEX_CONSTANTS } from './regex';
import { range } from 'lodash';

export const config = {
     svDocLength: 9,
     svPhoneLength: 8,
     svMaskedPhoneLength: 9,
     defaultFormValues: {
          HN: {
               documentType: 'CC',
               services: '1',
               home: 'false',
               time: 'AM'
          },
          PY: {
            documentType: 'CI',
            cliNationality: 'Nacionalidad'
          },
          GT: {
            documentType: 'DPI',
            accountType: '0',
            services: '1',
            issuingCountry: 'GUATEMALTECO',
            selectedAddress: '1',
            available_address: 'true',
            available_name_NIT: 'true'
          },
          BO: {
            documentType: 'CNA',
            identificationType: 'CI',
          },
          CR: {
            documentType: 'CED',
          },
          NI: {
            documentType: 'CI',
            issuingCountry: 'NICARAGUA',
            services:'1',
          }
     },
     valueFormat: {
          HN: {
               phoneNumber: { regex: REGEX_CONSTANTS.HN_PHONE_NUMBER , identifier: '$1 $2' },
               documentNumber: { regex: REGEX_CONSTANTS.HN_ID_CARD , identifier: '$1 $2 $3' },
          }
     },
     validations: {
          HN: {
               documentNumber: { minLength: 13 },
               cliTelephone: { minLength: 8 }
          }
     },
     locations: {
          HN:{
               latitude: 14.081999, 
               longitude: -87.202438,
          },
          GT: {
            latitude: 14.628434,
            longitude: -90.522713
          },
          CO: {
              latitude: 4.624579272849,
              longitude: -74.07157384298675
          },
          CR : { latitude: 9.9281, longitude: -84.0907 },
          PY:{
            latitude: 14.081999, 
            longitude: -87.202438,
        },
        BO : {
            latitude: -17.4140,
             longitude: -66.165 
          }
     },
     mask: {
          HN: {
               documentNumber: [ /\d/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/, ' ',  /\d/, /\d/,/\d/, /\d/, /\d/],
               phoneNumber: [ /(2|6|7)/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/],
               date: [/\d/, /\d/],
               year: [/\d/, /\d/,/\d/, /\d/],
               otp: [/\d/, /\d/,/\d/, /\d/,/\d/, /\d/]
          },
          GT: {
            phoneNumber: [ /(3|4|5)/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/],
            documentNumber: [ /\d/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/,/\d/, ' ',  /\d/, /\d/,/\d/, /\d/],
            paymentReceiptNumber: [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          },
          CO: {
            phoneNumber: [ '(', /\d/, /\d/, /\d/, ')', ' ', /\d/ , /\d/ ,/\d/ , ' ',  /\d/, /\d/, ' ', /\d/, /\d/],
            name: range(0,200).map(() => /^[A-Za-zñáéíóúÁÉÍÑÓÚüÜ ]+$/ ),
            document: range(0,40).map(() => /^[0-9]+$/ ),
          },
          BO: {
            phoneNumber: [/\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/],
          },
          CR: {
            phoneNumber: [ /\d/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/],
            numbers: range(0,20).map(() => /\d/ ),
            CED: [ /\d/, '-', /\d/, /\d/, /\d/, /\d/ , '-',  /\d/, /\d/, /\d/, /\d/],
            CRT: [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            JUR: [ /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            NIT: [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
          },
          NI: {
            phoneNumber: [ /\d/, /\d/, /\d/, /\d/ , ' ',  /\d/, /\d/, /\d/, /\d/],
            numbers: range(0,40).map(() => /\d/ ),
            documentNumber: [ /\d/, /\d/, /\d/ , '-',  /\d/, /\d/, /\d/, /\d/,/\d/,/\d/, '-',  /\d/, /\d/,/\d/, /\d/,/[A-Z]/],
            nis: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
          }
     },
     months: [
          {
               value: '',
               label: "",
               short: ""
           },
          {
              value: '01',
              label: "Enero",
              short: "Ene"
          },
          {
              value: '02',
              label: "Febrero",
              short: "Feb"
          },
          {
              value: '03',
              label: "Marzo",
              short: "Mar"
          },
          {
              value: '04',
              label: "Abril",
              short: "Abr"
          },
          {
              value: '05',
              label: "Mayo",
              short: "May"
          },
          {
              value: '06',
              label: "Junio",
              short: "Jun"
          },
          {
              value: '07',
              label: "Julio",
              short: "Jul"
          },
          {
              value: '08',
              label: "Agosto",
              short: "Ago"
          },
          {
              value: '09',
              label: "Septiembre",
              short: "Sep"
          },
          {
              value: '10',
              label: "Octubre",
              short: "Oct"
          },
          {
              value: '11',
              label: "Noviembre",
              short: "Nov"
          },
          {
              value: '12',
              label: "Diciembre",
              short: "Dic"
          }
      ],
      constants: {
          addonQuantityList: ['CHARGE FIXED', 'RECURRENT FIXED'],
          activationStatus: ['preactivated', 'completed'],
          activationPartialStatus: ['submitted', 'in progress'],
          ignorekeysListSignature: ['buro-consulta', 'buro-compartir', 'medios-solucion', 'aceptacion-cliente', 'aceptacion-vendedor'],
          ignorekeysListAttachment: ['buro-consulta', 'buro-compartir', 'medios-solucion', 'aceptacion-cliente', 'aceptacion-vendedor', 'DUI_Front', 'DUI_Back']
      }
}
