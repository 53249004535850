import React from 'react';
import { configHN } from './UIConfig_HN';
import { pageURL_PY, config, PY_TEXTS, appColors, imageURLs } from '../constants';

const newValues = {
  ClientInformation: {
    title: 'Ingresá tus datos',
    formFields: [
      {
        type: 'formRow',
        components: [
          {
            flexSpan: 0.75,
            marginRight: '15px',
            component: [
              {
                name: 'documentType',
                label: 'Tipo',
                id: 'documentType',
                type: 'collapsibleList',
                variant: 'outlined',
                flexSpan: 2,
                options: [
                  { value: 'CI', label: 'CI' },
                  { value: 'DE', label: 'DE' },
                ],
              },
            ],
          },
          {
            flexSpan: 2,
            component: [
              {
                name: 'documentNumber',
                label: 'Nro. Documento',
                id: 'documentNumber',
                type: 'tel',
                variant: 'outlined',
                inputProps: { mask: config.mask['HN'].documentNumber, maxLength: 20 },
                validations: {
                  maxLength: 30,
                },
                mask: (value) => value.documentType === 'CC',
              },
            ],
          },
        ],
      },
      {
        name: 'cliNationality',
        label: 'Nacionalidad',
        id: 'cliNationality',
        type: 'collapsibleList',
        variant: 'outlined',
        conditionalDisplay: true,
        options: [
          { value: 'Argentina', label: 'Argentina' },
          { value: 'Brasil', label: 'Brasil' },
          { value: 'Bolivia', label: 'Bolivia' },
          { value: 'Uruguay', label: 'Uruguay' },
        ],
      },
      {
        name: 'cliLastName',
        label: 'Apellidos',
        id: 'cliLastName',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'cliFirstName',
        label: 'Nombres',
        id: 'cliFirstName',
        type: 'text',
        variant: 'outlined',
      },
      {
        name: 'cliTelephone',
        label: 'Número celular',
        id: 'cliTelephone',
        type: 'tel',
        variant: 'outlined',
        inputProps: {maxLength: 8 },
        placeholder: '00000000',
        InputProps: {
          startAdornment: <span style={{marginRight: '5px', color: '#555555' }}>5959</span>,
        }
      },
      {
        name: 'cliEmail',
        label: 'Correo electrónico',
        id: 'cliEmail',
        type: 'text',
        variant: 'outlined',
      },
      {
        type: 'formRow',
        components: [
          {
            flexSpan: 1,
            marginRight: '15px',
            component: [
              {
                type: 'button',
                text: 'hfc',
                name: 'submit',
                alignSelf: 'center',
                disabled: false,
                setButtonText: true
              },
            ],
          },
          {
            flexSpan: 1,
            component: [
              {
                type: 'button',
                text: 'dth',
                name: 'submit',
                alignSelf: 'center',
                disabled: false,
                setButtonText: true
              },
            ],
          },
        ],
      },
      {
        type: 'button-group',
        buttons: [
          {
            text: 'cancelar',
            name: 'cancel',
            alignSelf: 'center',
            variant: 'rounded',
            disabled: false,
            type: 'cancel',
            style: {
              background: 'transparent',
              color: appColors.secondaryCyan,
            },
          },
        ],
      },
    ],
    redirectURLs: {
      redirectURL: pageURL_PY.error,
      nextURL: pageURL_PY.inputAddress
    },
  },
};

export const configPY = {
  ...configHN,
  ...newValues,
  HomePage: {
    formFields: [],
    sidebarMenu: [
      {
        title: PY_TEXTS.HOME_NEW_CLIENT,
        url: pageURL_PY.locationServices,
        iconType: '',
      },
      {
        title: PY_TEXTS.HOME_SALES_REPORT,
        url: pageURL_PY.salesReport,
        iconType: '',
      },
      {
        title: PY_TEXTS.HOME_LOGOUT,
        url: '',
        iconType: '',
      },
    ],
  },
  ValidationError: {
    icon: imageURLs.failed,
    redirectURLs: {
      prevURL: pageURL_PY.clientInformation
    }
  },
  ConfirmOrder : {
    title : 'Confirmá tu orden',
    redirectURLs : {
      progress :  pageURL_PY.transactionInProgress,
      success  : pageURL_PY.orderSuccess,
      fail : pageURL_PY.orderFail, 
      product: pageURL_PY.products
    }
  }
};
