import { pageURL_CO } from '../../constants/URLs';
import { UIConfig as COConfig } from '../UIConfig_CO';

export const CO_ROUTES = {
  appRoutes: [
    {
      path: '/',
      component: 'InputSingleLine',
      formData: COConfig('inputSingleLine'),
    },
    {
      path: pageURL_CO.inputSingleLine,
      component: 'InputSingleLine',
      formData: COConfig('inputSingleLine'),
    },
    {
      path: pageURL_CO.leadToCall,
      component: 'LeadCall',
      formData: COConfig('leadToCall'),
    },
    {
      path: pageURL_CO.leadToCallConfirmation,
      component: 'LeadCallConfirmation',
      formData: COConfig('leadCallConfirmation'),
    },
    {
      path: pageURL_CO.maintenance,
      component: 'Maintenance',
    },
  ],
  privateAppRoutes: [
    {
      path: pageURL_CO.packages,
      component: 'AvailablePackages',
      formData: COConfig('availablePackages'),
    },
    {
      path: pageURL_CO.hfcFailed,
      component: 'HFCFailed',
      formData: COConfig('hfcFailed'),
    },
    {
      path: pageURL_CO.inputAddress,
      component: 'InputAddress',
      formData: COConfig('inputAddress'),
    },
    {
      path: pageURL_CO.noCoverage,
      component: 'HFCFailed',
      formData: COConfig('noCoverage'),
    },
    {
      path: pageURL_CO.noAddress,
      component: 'HFCFailed',
      formData: COConfig('noCoverage'),
    },
    {
      path: pageURL_CO.personalInfo,
      component: 'PersonalInfo',
      formData: COConfig('PersonalInfo'),
    },
    {
      path: pageURL_CO.orderSuccess,
      component: 'OrderSuccess',
      formData: COConfig('orderSuccess'),
    },
    {
      path: pageURL_CO.inputSingleLine,
      component: 'InputSIngleLine',
      formData: COConfig('inputSingleLine'),
    },
    {
      path: pageURL_CO.inputMap,
      component: 'InputMap',
      formData: COConfig('inputMap'),
    },
    {
      path: pageURL_CO.existingClient,
      component: 'ExistingClient',
      formData: COConfig('existingClient'),
    },
    {
      path: pageURL_CO.existingClientNewDirection,
      component: 'HFCFailed',
      formData: COConfig('existingClientNewDirection'),
    },
    {
      path: pageURL_CO.creditScoreRejected,
      component: 'HFCFailed',
      formData: COConfig('creditScoreRejected'),
    },
    {
      path: pageURL_CO.creditScoreRejectedLow,
      component: 'HFCFailed',
      formData: COConfig('creditScoreRejectedLow'),
    },
    {
      path: pageURL_CO.validateOtp,
      component: 'ValidateOtp',
    },
    {
      path: pageURL_CO.otpExpired,
      component: 'OtpExpired',
    },
    {
      path: pageURL_CO.varificationSuccess,
      component: 'VarificationSuccess',
    },
    {
      path: pageURL_CO.incorrectOtp,
      component: 'IncorrectOtp',
    },
    {
      path: pageURL_CO.securityQs,
      component: 'SecurityQs',
    },
    {
      path: pageURL_CO.antiFraudValidationFail,
      component: 'HFCFailed',
      formData: COConfig('antiFraudValidationFail'),
    },
    {
      path: pageURL_CO.verificationError,
      component: 'HFCFailed',
      formData: COConfig('verificationError'),
    },
    { path: pageURL_CO.unexpectedError, component: 'UnexpectedError' },
    {
      path: pageURL_CO.processShedule,
      component: 'ProcessShedule',
    },
    {
      path: pageURL_CO.termsConditions,
      component: 'TermsNCondition',
    },
    {
      path: pageURL_CO.validationSuccess,
      component: 'OrderSuccess',
      formData: COConfig('orderSuccess'),
    },
    {
      path: pageURL_CO.otpSkip,
      component: 'OtpSkip',
    },
    {
      path: pageURL_CO.selectMobileNum,
      component: 'PhoneNumberSelection',
    },
  ],
};
