import { pageURL_HN, HN_TEXTS, texts, imageURLs, config, HN_DEPARTMENTS, HN_MUNCIPALITIES, HN_CANCEL_REASONS, appColors } from '../constants';
import { isValidDateHN  , getLabelforDropdown} from '../utils/utils'


export const configHN = {
    HomePage: {
        formFields: [],
        sidebarMenu: [
            {
                title: HN_TEXTS.HOME_NEW_CLIENT,
                url: pageURL_HN.clientInformation,
                iconType: ''
            },
            {
                title: HN_TEXTS.HOME_SALES_REPORT,
                url: pageURL_HN.salesReport,
                iconType: ''
            },
            {
                title: HN_TEXTS.HOME_LOGOUT,
                url: '',
                iconType: ''
            }
        ]
    },
    ClientInformation: {
        title: HN_TEXTS.CLIENT_INFORMATION_TITLE,
        formFields: [
            {
                type: 'formRow',
                components: [
                    {
                        flexSpan: 1,
                        marginRight: '15px',
                        component: [{
                            name: 'documentType',
                            label: 'Tipo',
                            id: 'documentType',
                            type: 'collapsibleList',
                            variant: 'outlined',
                            flexSpan: 2,
                            options: [
                                { value: 'CC', label: 'CC - Cédula de identidad' },
                                { value: 'PA', label: 'PA - Pasaporte' },
                                { value: 'CR', label: 'CR - Carné de residente' }
                            ]
                        }]
                    },
                    {
                        flexSpan: 2,
                        component: [{
                            name: 'documentNumber',
                            label: 'Nro. Documento',
                            id: 'documentNumber',
                            type: 'tel',
                            variant: 'outlined',
                            inputProps: { mask: config.mask['HN'].documentNumber },
                            validations: {
                                maxLength: 30
                            },
                            mask: (value) => value.documentType === 'CC',
                        }]
                    }
                ]
            },
            {
                name: 'cliFirstName',
                label: 'Nombres',
                id: 'cliFirstName',
                type: 'text',
                variant: 'outlined',
            },
            {
                name: 'cliLastName',
                label: 'Apellidos',
                id: 'cliLastName',
                type: 'text',
                variant: 'outlined',
            },
            {
                name: 'cliTelephone',
                label: 'Número celular',
                id: 'cliTelephone',
                type: 'tel',
                variant: 'outlined',
                inputProps: { mask: config.mask['HN'].phoneNumber },
                validations: {
                    minLength: 8
                },
                mask: true,
            },
            {
                type: 'button',
                text: 'confirmar',
                name: 'submit',
            },
        ],
        redirectURLs: {
            prevURL: pageURL_HN.home,
            nextURL: pageURL_HN.locationServices,
            redirectURL: {
                active: pageURL_HN.activeTransaction,
                existing: pageURL_HN.existingServices,
            }
        },
    },

    ActiveTransaction: {
        title: HN_TEXTS.ACTIVE_TRANSACTION_TITLE,
        content: HN_TEXTS.ACTIVE_TRANSACTION_CONTENT,
        redirect: HN_TEXTS.ACTIVE_TRANSACTION_REDIRECT,
        icon: imageURLs.confirmation,
        subContent: '<label class=textGrey>También puedes terminar la transacción en un <span class=textTeal>PUNTO DE PAGO LOCAL</span></label> <label class=textGrey>Recuerda usar este número de contrato: </label>',
        redirectURLs: {
            home: pageURL_HN.home
        }
    },
    ExistingServices: {
        title: HN_TEXTS.EXISTING_SERVICES_TITLE,
        formFields: [{
            type: 'radio',
            options: [
                { value: '0', text: 'Modificar servicios existentes' },
                { value: '1', text: 'Nueva residencia' }
            ],
            name: 'services',
            id: 'services'
        }, {
            type: 'button',
            text: 'CONTINUAR',
            name: 'submit',
        },],
        redirectURLs: {
            prevURL: pageURL_HN.clientInformation,
            redirection: pageURL_HN.mitigoRedirection,
            newAddress: pageURL_HN.locationServices,
            home: pageURL_HN.home
        },
    },
    MitigoRedirection: {
        redirect: HN_TEXTS.CONTINUE_HOME,
        icon: imageURLs.confirmation,
        redirectURLs: {
            redirectURL: pageURL_HN.clientInformation,
            prevURL: pageURL_HN.existingServices,
            home: pageURL_HN.home
        },
    },
    LocationActivation: {
        title: HN_TEXTS.ACTIVATE_LOCATION_TITLE,
        redirectURLs: {
            nextURL: pageURL_HN.inputAddress,
            prevURL: pageURL_HN.clientInformation,
            home: pageURL_HN.home
        },
    },
    InputAddress: {
        title: HN_TEXTS.INPUT_ADDRESS_TITLE,
        formFields: [
            {
                name: 'department',
                label: 'Departamento',
                id: 'department',
                type: 'autocomplete',
                options: HN_DEPARTMENTS,
                getOptionLabel: (option) => option['DEPARTAMENTO'] || option,
                variant: 'outlined',
                noOptionsText: "Sin opciones",
                dependency: ['suburb', 'city']
            },
            {
                name: 'city',
                label: 'Ciudad',
                id: 'city',
                type: 'autocomplete',
                options: HN_MUNCIPALITIES,
                getOptionLabel: (option) => option['MUNICIPIO'] || option,
                variant: 'outlined',
                noOptionsText: "Sin opciones",
                dependency: ['suburb']
            },

            {
                name: 'suburb',
                label: 'Colonia',
                id: 'suburb',
                type: 'autocomplete',
                getOptionLabel: (option) => option.description || option,

                variant: 'outlined',
                noOptionsText: "Sin opciones",

            },
            {
                name: 'street',
                label: 'Bloque/Calle',
                id: 'street',
                type: 'text',
                variant: 'outlined',
            },
            {
                name: 'house',
                label: '# de casa o edificio',
                id: 'house',
                type: 'text',
                variant: 'outlined',
            },
            {
                name: 'addressref',
                label: 'Referencia de dirección',
                id: 'addressref',
                type: 'textarea',
                variant: 'outlined',
                rows: 5,
                multiline: true,
                optional: true,
                helperText: (value) => `${255 - value.length} / 255`,
                inputProps: {
                    maxLength: 255,
                }
            },
            {
                type: 'checkbox',
                value: 'home',
                label: 'Me encuentro en la casa del cliente',
                id: 'home',
            },
            {
                type: 'button-group',
                buttons: [
                    { type: 'cancel', text: 'regresar', color: "transparent", textColor: appColors.palette.teal, disabled: false, className: 'cancel' },
                    { type: 'submit', text: 'confirmar' },
                ]
            },
        ],
        optionalParams: ['addressref'],
        redirectURLs: {
            prevURL: pageURL_HN.locationServices,
            nextURL: pageURL_HN.map,
            home: pageURL_HN.home
        },
    },
    LocationService: {
        redirectURLs: {
            prevURL: pageURL_HN.inputAddress,
            nextURL: pageURL_HN.products,
            redirectURL: pageURL_HN.offerNotAvailable,
            home: pageURL_HN.home
        },
    },
    NoOffer: {
        title: HN_TEXTS.NO_OFFER_TITLE,
        content: HN_TEXTS.NO_OFFER_DESCRIPTION,
        redirect: HN_TEXTS.CONTINUE_HOME,
        icon: imageURLs.failed,
        redirectURLs: {
            redirectURL: pageURL_HN.clientInformation,
            prevURL: pageURL_HN.map,
            home: pageURL_HN.home
        }

    },
    ProductSelection: {
        tabs: ['PAQUETE', 'TV', 'INTERNET'],
        redirectURLs: {
            nextURL: pageURL_HN.addons,
            prevURL: pageURL_HN.map,
            home: pageURL_HN.home
        },
        progress: '<span class=progress-status>1/12</span>'
    },
    Addons: {
        title: HN_TEXTS.HN_ADDONS_TITLE,
        redirectURLs: {
            nextURL: pageURL_HN.checkout,
            prevURL: pageURL_HN.products,
            home: pageURL_HN.home
        },
        progress: '<span class=progress-status>2/12</span>'
    },
    Checkout: {
        title: texts.CART_EMPTY_TITLE,
        redirectURLs: {
            nextURL: pageURL_HN.idScanFront,
            prevURL: pageURL_HN.addons,
            home: pageURL_HN.home,
            redirectURL: pageURL_HN.products,
            jumpToURL: pageURL_HN.activation
        },
        progress: '<span class=progress-status>3/12</span>'
    },
    IDReview: {
        title: HN_TEXTS.HN_ID_REVIEW_CONFIRMATION,
        formFields: [
            {
                name: 'documentNumber',
                label: 'Número de documento',
                id: 'documentNumber',
                type: 'tel',
                variant: 'outlined',
                inputProps: { mask: config.mask['HN'].documentNumber },
                mask: (value) => value.documentType === 'CC',
                validations: {
                    maxLength: 30
                },
            },
            {
                name: 'cliFirstName',
                label: 'Nombres',
                id: 'cliFirstName',
                type: 'text',
                variant: 'outlined',
            },
            {
                name: 'cliLastName',
                label: 'Apellidos',
                id: 'cliLastName',
                type: 'text',
                variant: 'outlined',
            },
            {
                text: 'Fecha de nacimiento',
                type: 'label',
                style: { color: '#565656', fontSize: '12px' }
            },
            {
                type: 'formRow',
                components: [
                    {
                        flexSpan: 1,
                        marginRight: '15px',
                        component: [{
                            name: 'birthDate',
                            label: 'Día',
                            id: 'birthDate',
                            type: 'tel',
                            variant: 'outlined',
                            inputProps: { mask: config.mask['HN'].date },
                            validations: {
                                minLength: 2,
                                maxValue: 31
                            },
                            mask: true,
                            dependency: 'birthDate',
                            customValidator: (formData, value) => !isValidDateHN(value, config.months.find(month => month.short.toLowerCase() === formData['birthMonth'].toLowerCase()).value, formData['birthYear'])
                        }]
                    },
                    {
                        flexSpan: 2,
                        marginRight: '15px',
                        component: [{
                            name: 'birthMonth',
                            label: 'Mes',
                            id: 'birthMonth',
                            type: 'select',
                            variant: 'outlined',
                            options: config.months.map(month => month.short),
                            dependency: 'birthDate',
                            customValidator: (formData, value) => !isValidDateHN(formData['birthDate'], config.months.find(month => month.short.toLowerCase() === value.toLowerCase()).value, formData['birthYear'])
                        }]
                    },
                    {
                        flexSpan: 1,
                        component: [{
                            name: 'birthYear',
                            label: 'Año',
                            id: 'birthYear',
                            type: 'tel',
                            variant: 'outlined',
                            flexSpan: 2,
                            inputProps: { mask: config.mask['HN'].year },
                            validations: {
                                minLength: 4,
                                maxValue: 2019
                            },
                            mask: true,
                            dependency: 'birthDate',
                            customValidator: (formData, value) => !isValidDateHN(formData['birthDate'], config.months.find(month => month.short.toLowerCase() === formData['birthMonth'].toLowerCase()).value, value)
                        }]
                    },
                ]
            },
            {
                name: 'gender',
                label: 'Género',
                id: 'gender',
                type: 'select',
                variant: 'outlined',
                options: ['', 'Masculino', 'Femenino']
            },
            {
                type: 'button',
                text: 'confirmar',
                name: 'submit',
            },
        ],
        redirectURLs: {
            prevURL: pageURL_HN.idScanFront,
            nextURL: pageURL_HN.contractInfo,
            home: pageURL_HN.home,
            redirectURL: pageURL_HN.idNotValid
        },
        progress: '<span class=progress-status>5/12</span>'
    },
    IDFront: {
        title: HN_TEXTS.HN_ID_FRONT_TITLE,
        description: '<span>A continuación tomaremos una foto de <b>la cara frontal</b> de la Tarjeta de Identidad.<span>',
        redirectURLs: {
            nextURL: pageURL_HN.home,
            prevURL: pageURL_HN.checkout,
            home: pageURL_HN.home,
            redirectURL: pageURL_HN.idReview
        },
        scannerConfig: {
            fileName: 'ID_front_side',
            field: 'idFront',
            getImageFunc: 'uploadFront',
            compressionConfig: {
                maxSizeMB: 1,
                useWebWorker: true,
            },
            redirectURL: pageURL_HN.home,
        },
        progress: '<span class=progress-status>4/12</span>'
    },
    IdNotValid: {
        title: HN_TEXTS.HN_ID_INVALID_TITLE,
        content: HN_TEXTS.HN_ID_INVALID_DESCRIPTION,
        icon: imageURLs.failed,
        redirectURLs: {
            redirectURL: pageURL_HN.idReview,
            prevURL: pageURL_HN.idReview,
            home: pageURL_HN.home
        }
    },
    ContractInfo: {
        title: HN_TEXTS.HN_CONTRACT_INFO_TITLE,
        description: HN_TEXTS.HN_CONTRACT_INFO_DESCRIPTION,
        formFields: [
            {
                name: 'email',
                label: 'Correo electrónico',
                id: 'email',
                type: 'text',
                variant: 'outlined',
                helperText: 'Este correo electrónico será usado para enviar tu factura',
            },
            {
                name: 'cliTelephone',
                label: 'Número celular',
                id: 'cliTelephone',
                type: 'tel',
                variant: 'outlined',
                helperText: 'Campo requerido',
                inputProps: { mask: config.mask['HN'].phoneNumber },
                mask: true,
                validations: {
                    minLength: 8
                },
            },
            {
                name: 'residencyNumber',
                label: 'Número de residencia',
                id: 'residencyNumber',
                type: 'tel',
                variant: 'outlined',
                optional: true,
                inputProps: { mask: config.mask['HN'].phoneNumber },
                mask: true,
                validations: {
                    minLength: 8
                },
            },
            {
                title: 'Referencia personal #1',
                type: 'inputList',
                options: [
                    {
                        name: 'name',
                        label: 'Nombre completo',
                        id: 'refNameA',
                        type: 'text',
                        variant: 'outlined',
                        helperText: 'Campo requerido',
                        inputProps: {
                            maxLength: 19,
                        }
                    },
                    {
                        name: 'telephone',
                        label: 'Teléfono',
                        id: 'refTelephoneA',
                        type: 'tel',
                        variant: 'outlined',
                        helperText: 'Campo requerido',
                        inputProps: { mask: config.mask['HN'].phoneNumber },
                        mask: true,
                        validations: {
                            minLength: 8
                        },
                    },
                ],
            },
            {
                title: 'Referencia personal #2',
                type: 'inputList',
                options: [
                    {
                        name: 'name',
                        label: 'Nombre completo',
                        id: 'refNameB',
                        type: 'text',
                        variant: 'outlined',
                        helperText: 'Campo requerido',
                        inputProps: {
                            maxLength: 19,
                        }
                    },
                    {
                        name: 'telephone',
                        label: 'Teléfono',
                        id: 'refTelephoneB',
                        type: 'tel',
                        variant: 'outlined',
                        helperText: 'Campo requerido',
                        inputProps: { mask: config.mask['HN'].phoneNumber },
                        mask: true,
                        validations: {
                            minLength: 8
                        },
                    },
                ],
            },
            {
                type: 'button',
                text: 'confirmar',
                name: 'submit',
            },
        ],
        optionalParams: ['residencyNumber'],
        redirectURLs: {
            prevURL: pageURL_HN.idReview,
            nextURL: pageURL_HN.verifyPhone,
            home: pageURL_HN.home,
            redirectURL: pageURL_HN.idNotValid
        },
        progress: '<span class=progress-status>6/12</span>'
    },
    VerifyPhone: {
        title: (value) => `${HN_TEXTS.HN_VERIFY_PHONE_TITLE} al ${value.cliTelephone}`,
        inputConfig: {
            name: 'otp',
            label: 'Código de verificación',
            id: 'otp',
            type: 'tel',
            variant: 'outlined',
            inputProps: { mask: config.mask['HN'].otp },
            validations: {
                minLength: 6
            },
        },
        buttonConfigPrimary: {
            type: 'button',
            text: 'VERIFICAR',
            name: 'submit',
        },
        buttonConfig: {
            color: 'transparent',
            textColor: appColors.secondaryCyan,
            variant: 'rounded',
            text: 'Solicita uno nuevo',
            textTransform: true,
            style: { fontSize: '16px' }
        },
        labelConfig: {
            text: '¿No has recibido el código?',
            fontColor: appColors.textColorGrey,
            style: { marginTop: '30px' }
        },
        progress: '<span class=progress-status>7/12</span>',
        redirectURLs: {
            prevURL: pageURL_HN.contractInfo,
            nextURL: pageURL_HN.verifyEmail,
            redirectURL: pageURL_HN.home,
            home: pageURL_HN.home
        },
        type: 'phone',
        otpChannel: 'SMS'
    },
    VerifyEmail: {
        title: (value) => `${HN_TEXTS.HN_VERIFY_PHONE_TITLE} al ${value.email}`,
        inputConfig: {
            name: 'otp',
            label: 'Código de verificación',
            id: 'otp',
            type: 'tel',
            variant: 'outlined',
            inputProps: { mask: config.mask['HN'].otp },
            validations: {
                minLength: 6
            },
        },
        verifyButton: {
            text: 'VERIFICAR',
            variant: 'rounded'
        },
        skipButton: {
            text: 'OMITIR',
            color: 'transparent',
            textColor: appColors.palette.teal,
            variant: "rounded",
            marginTop: 20
        },
        buttonConfig: {
            color: 'transparent',
            textColor: appColors.secondaryCyan,
            variant: 'rounded',
            text: 'Solicita uno nuevo',
            textTransform: true,
            style: { fontSize: '16px' }
        },
        labelConfig: {
            text: '¿No has recibido el código?',
            fontColor: appColors.textColorGrey,
            style: { marginTop: '30px' }
        },
        progress: '<span class=progress-status>8/12</span>',
        redirectURLs: {
            prevURL: pageURL_HN.verifyPhone,
            nextURL: pageURL_HN.activation,
            redirectURL: pageURL_HN.home,
            home: pageURL_HN.home
        },
        type: 'email',
        otpChannel: 'email'
    },
    Activation: {
        title: HN_TEXTS.HN_CONFIRM_ORDER,
        redirectURLs: {
            nextURL: pageURL_HN.scheduleVisit,
            prevURL: pageURL_HN.verifyEmail,
            redirectURL: pageURL_HN.products,
            home: pageURL_HN.home,
            jumpToURL: pageURL_HN.orderSuccess,
            cancel: pageURL_HN.clientInformation
        },
        progress: '<span class=progress-status>9/12</span>',
        timerDelay: 5000,
        maxLimit: 1000,
        formFields: [{
            type: 'radio',
            options: [
                { value: '0', text: 'Pagar al instalador' },
                { value: '1', text: 'Pagar al vendedor' },
                { value: '2', text: 'Otro (tarjeta, Tigo Money, banco)' }
            ],
            name: 'paymentOption',
            id: 'paymentOption'
        }]
    },
    OrderSuccess: {
        title: HN_TEXTS.HN_ORDER_SUCCESS,
        content: HN_TEXTS.ACTIVE_TRANSACTION_CONTENT,
        redirect: HN_TEXTS.HN_ORDER_SUCCESS_BUTTON,
        icon: imageURLs.confirmation,
        redirectURLs: {
            home: pageURL_HN.home,
            redirectURL: pageURL_HN.clientInformation
        },
        menu: [{ left: '/images/Mi-Tigo-ic.svg', center: 'Mi Tigo', right: '/images/ico_arrow-dropdown.svg' },
        { left: '/images/tigo-money-ic.svg', center: 'Tigo Money', right: '/images/ico_arrow-dropdown.svg' },
        { left: '/images/Sucursal-ic.svg', center: 'Sucursales', right: '/images/ico_arrow-dropdown.svg' },
        { left: '/images/bancos-ic.svg', center: 'Bancos', right: '/images/ico_arrow-dropdown.svg' },
        ]
    },
    SalesReport: {
        title: texts.SALES_REPORT,
        sidebarMenu: [
            {
                title: HN_TEXTS.HOME_NEW_CLIENT,
                url: pageURL_HN.clientInformation,
                iconType: ''
            },
            {
                title: HN_TEXTS.HOME_SALES_REPORT,
                url: pageURL_HN.salesReport,
                iconType: ''
            },
            {
                title: HN_TEXTS.HOME_LOGOUT,
                url: '',
                iconType: ''
            }
        ],
        formFields : [{
                name: 'Razón de cancelación',
                label: 'Razón de cancelación',
                id: 'reason',
                type: 'select',
                variant: 'outlined',
                options : getLabelforDropdown(HN_CANCEL_REASONS , 'label')
            },
        ]
    },
    ScheduleVisit: {
        title: HN_TEXTS.HN_SCHEDULE_VISIT_TITLE,
        formFields: [
            {
                type: 'radio',
                options: [
                    { value: 'AM', text: 'AM' },
                    { value: 'PM', text: 'PM' }
                ],
                name: 'time',
                id: 'time',
                dependency: ['installationDate']
            },
            {
                name: 'installationDate',
                id: 'installationDate',
                type: 'date',
                variant: 'outlined',
                placeholder: 'DD/MM/AAAA',
                disablePast: true,
                label: 'Seleccionar fecha',
            },
            {
                name: 'cliTelephone',
                label: 'Teléfono',
                id: 'cliTelephone',
                type: 'tel',
                variant: 'outlined',
                helperText: 'Para confirmar la instalación',
                inputProps: { mask: config.mask['HN'].phoneNumber },
                mask: true
            },
            {
                name: 'actComments',
                label: 'Mensaje para el instalador',
                id: 'actComments',
                type: 'textarea',
                variant: 'outlined',
                optional: true,
                multiline: true,
                rows: 5
            },
            {
                type: 'button',
                text: 'confirmar',
                name: 'submit',
            },
        ],
        optionalParams: ['actComments'],
        redirectURLs: {
            prevURL: pageURL_HN.activation,
            nextURL: pageURL_HN.orderSuccess,
            home: pageURL_HN.home,
        },
        calendarButtonProps: {
            cancel: 'CANCELAR',
            confirm: 'GUARDAR'
        },
        progress: '<span class=progress-status>10/12</span>'
    }
}