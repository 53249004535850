import { generateKeyPair } from 'jose/dist/browser/util/generate_key_pair';
import { fromKeyLike } from 'jose/dist/browser/jwk/from_key_like';
import { CompactEncrypt } from 'jose/dist/browser/jwe/compact/encrypt';
import { CompactSign } from 'jose/dist/browser/jws/compact/sign';
import { compactDecrypt } from 'jose/dist/browser/jwe/compact/decrypt';
import { compactVerify } from 'jose/dist/browser/jws/compact/verify';
import parseJwk from 'jose/dist/browser/jwk/parse';
import crypto from 'crypto';
import { isEmpty } from 'lodash';
import secureStorage from './secureWebStorage';

export const deleteSecrets = () => {
  secureStorage.removeItem('FEPvtKey');
  secureStorage.removeItem('FEPubKey');
  secureStorage.removeItem('BEPubKey');
};

export const getResKeys = async () => {
  const resKey = await generateKeyPair('ECDH-ES', { extractable: true });
  const { publicKey, privateKey } = resKey;
  const resPrivateJwk = await fromKeyLike(privateKey); //stored in local storage
  const resPublicJwk = await fromKeyLike(publicKey); // send to BE
  secureStorage.setItem('FEPvtKeyRes', resPrivateJwk);
  secureStorage.setItem('FEPubKeyRes', resPublicJwk);
  return { resPrivateJwk, resPublicJwk };
};

export const getKeysFE = async () => {
  const gKey = await generateKeyPair('ES256', { extractable: true });
  const { publicKey, privateKey } = gKey;
  const privateJwk = await fromKeyLike(privateKey); //stored in local storage
  const publicJwk = await fromKeyLike(publicKey); // send to BE
  secureStorage.setItem('FEPvtKey', privateJwk);
  secureStorage.setItem('FEPubKey', publicJwk);
  const resKeys = await getResKeys();
  return { privateJwk, publicJwk, ...resKeys };
};

export const getPvtKeyFEJwk = async () => {
  return secureStorage.getItem('FEPvtKey');
};

export const getPubKeyFEJwk = async () => {
  const keys = await getKeysFE();
  return { publicJwk: keys.publicJwk, resPubJwk: keys.resPublicJwk };
};

export const getPvtKeyFE = async () => {
  const FEPvtKey = secureStorage.getItem('FEPvtKey');
  let privateKeyFE = {};
  if (FEPvtKey) {
    try {
      privateKeyFE = await parseJwk(FEPvtKey, 'ES256');
    } catch (e) {
      return privateKeyFE;
    }
  }
  return privateKeyFE;
};

export const getPvtKeyFERes = async () => {
  const FEPvtKeyRes = secureStorage.getItem('FEPvtKeyRes');
  let resPrivateKeyFE = {};
  if (FEPvtKeyRes) {
    try {
      resPrivateKeyFE = await parseJwk(FEPvtKeyRes, 'ECDH-ES');
    } catch (e) {
      return resPrivateKeyFE;
    }
  }
  return resPrivateKeyFE;
};

export const getPubKeyFE = async () => {
  return secureStorage.getItem('FEPubKey');
};

export const getPubKeyBE = async () => {
  const pubJwk = secureStorage.getItem('BEPubKey');
  let pubKeynew = null;
  if (!isEmpty(pubJwk)) {
    try {
      pubKeynew = await parseJwk(pubJwk, 'ECDH-ES');
    } catch (e) {
      return pubKeynew;
    }
  }
  return pubKeynew;
};

export const encryptData = async (encryptInfo) => {
  const encryptKey = await getPubKeyBE();
  if (encryptKey && encryptInfo) {
    const encoder = new TextEncoder();
    return new CompactEncrypt(encoder.encode(encryptInfo))
      .setProtectedHeader({ alg: 'ECDH-ES', enc: 'A256GCM' })
      .encrypt(encryptKey);
  }
  return encryptInfo;
};

export const getEncryptedKeys = async (state, nonce) => {
  const encryptedState = await encryptData(state);
  const encryptedNonce = await encryptData(nonce);
  return { encryptedState, encryptedNonce };
};

export const signHeader = async (payloadForSign) => {
  const shaMsg = crypto.createHash('md5').update(JSON.stringify(payloadForSign)).digest('hex');
  const encoder = new TextEncoder();
  const pvtJwe = await getPvtKeyFE();
  if (pvtJwe) {
    return new CompactSign(encoder.encode(shaMsg)).setProtectedHeader({ alg: 'ES256' }).sign(pvtJwe);
  }
  return payloadForSign;
};

export const decryptHeader = async (header) => {
  const FEPvtKeyRes = await getPvtKeyFERes();
  const { plaintext } = await compactDecrypt(header, FEPvtKeyRes);
  const decoder = new TextDecoder();
  return decoder.decode(plaintext);
};

// const b = await new CompactSign(encoder.encode(shaMsg)).setProtectedHeader({ alg: 'ES256' }).sign(pvtJwe);
// verify(await getPubKeyFE(), b);
// return b;

async function verify(publicJwk, signature) {
  try {
    console.log({ publicJwk, signature });
    console.log('local varify');
    console.log('lockal public key', publicJwk);
    console.log('lockal signature', signature);
    const publicKey = await parseJwk(publicJwk, 'ES256');
    const { payload, protectedHeader } = await compactVerify(signature, publicKey);
    console.log('lockal payload', payload);
  } catch (error) {
    console.log('\nSignature Verification failed.\n', error);
    throw error;
  }
}
