import { pageURL_BO } from 'constants/URLs';

export default {
  appRoutes: [
    {
      path: pageURL_BO.clientInformation,
      component: 'ClientInformation',
    },
    {
      path: pageURL_BO.packages,
      component: 'AvailablePackages',
    },
    {
      path: `${pageURL_BO.verifyPhone}/:id?/:node`,
      component: 'VerifyPhone',
    },
  ],
  privateAppRoutes: [
    {
      path: pageURL_BO.inputMap,
      component: 'InputMap',
    },
    {
      path: pageURL_BO.locationAccess,
      component: 'LocationAccess',
    },
    {
      path: pageURL_BO.inputAddress,
      component: 'InputAddress',
    },
    {
      path: pageURL_BO.recievedRequest,
      component: 'RecievedRequest',
    },
    {
      path: pageURL_BO.confirmOrder,
      component: 'ConfirmOrder',
    },
    {
      path: pageURL_BO.clientIdentification,
      component: 'ClientIdentification',
    },
    {
      path: pageURL_BO.existingDebt,
      component: 'ExistingDebt',
    },
    {
      path: pageURL_BO.existingClient,
      component: 'ExistingClient',
    },
    {
      path: pageURL_BO.idScanFront,
      component: 'IDFront',
    },
    {
      path: pageURL_BO.idScanRear,
      component: 'IDRear',
    },
    {
      path: pageURL_BO.selfie,
      component: 'SelfieUpload',
    },
    {
      path: pageURL_BO.verifyPhone,
      component: 'VerifyPhone',
    },
    {
      path: pageURL_BO.verifyEmail,
      component: 'VerifyEmail',
    },
    {
      path: pageURL_BO.scheduleVisit,
      component: 'ScheduleVisit',
    },
    {
      path: pageURL_BO.orderSuccessOther,
      component: 'OrderSuccessOther',
    },
    {
      path: pageURL_BO.orderSuccessQR,
      component: 'OrderSuccessQR',
    },
    {
      path: pageURL_BO.orderSuccessCredit,
      component: 'OrderSuccessCredit',
    },
    {
      path: pageURL_BO.payment,
      component: 'Payment',
    },
  ],
};
