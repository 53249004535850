import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import { 
  track, 
  updateGlobalTracker,
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
 } from '../../utils/segmenTracker';
import {
  ORDER_FAILED,
  ACTIVATION,
  ORDER_COMPLETED,
  SERVER_RESPONSE_FAILED,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";
import { pinEncryptKeyPair } from "../../config/rsa";
import { pageURLs } from '../../constants';

const NodeRSA = require("node-rsa");
const key = new NodeRSA(pinEncryptKeyPair);

const initialState = {
  clientDetails: {},
  activationResponse: null,
  readyForActivation: false,
  modifyLeadId: null
};

export const activation = {
  state: { ...initialState },
  reducers: {
    updateActivateStatus (state, payload) {
      return { ...state, activationResponse: payload };
    },
    updateClientInformation (state, payload) {
      return {
        ...state,
        clientDetails: { ...state.clientDetails, ...payload },
      };
    },
    updateActivationProcess(state, payload) {
      return {
        ...state,
        readyForActivation: payload
      }
    },
    updateModifyLeadId(state, payload) {
      return {
        ...state,
        modifyLeadId: payload
      }
    },
    clearActivation() {
      return { ...initialState}
    }
  },
  effects: dispatch => {
    return {
      async activate (data, state) {
        const { callback, ...rest } = data
        delete rest['back_id'];
        dispatch.apiStatus.setProcessing(true);
        dispatch.activation.updateActivateStatus(null)
        fetchApi({
          method: 'post',
          url: svAPI.activation,
          data: { ...rest, ifMultichannel: 'S', vendPass: key.encrypt(state.auth.data.pin, 'base64') },
        })
          .then(response => {
            dispatch.activation.updateActivateStatus(response.data);
            const { leadId } = response.data;
            updateGlobalTracker({lead_id: leadId})
            callback && callback(pageURLs.orderSuccess, true)
            dispatch.apiStatus.setProcessing(false);
            track(ORDER_COMPLETED, { journey: ACTIVATION });
          })
          .catch(error => {
            dispatch.activation.updateActivateStatus({ ...error });
            const additionalData = {
              journey: ACTIVATION,
              error_id: error.errorCode,
              error_message: error.message,
              error_type: 'error'
            }
            track(ORDER_FAILED, additionalData);
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Confirm Order', svAPI.activation, 'Order API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Order API')})
            dispatch.apiStatus.setProcessing(false);
            dispatch.apiStatus.setRequestFailed({ ...error });
            if(error.errorCode === 504){
              callback && callback(pageURLs.orderTimeout, false)
            } else {
              callback && callback(pageURLs.orderFailed, false)
            }
          });
      },
    };
  },
};