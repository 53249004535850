import React, { useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { BACK_CLICK } from 'constants/segmentEvents';
import { isMobile } from 'utils/deviceInfo';
import { updateGlobalTracker, splitURL, track } from 'utils/segmenTracker';

const LocationLogger = () => {
  useEffect(() => {
    window.isHABack = false;
    window.landing = '';
    window.last = '';
  }, []);
  const lastLocation = useLastLocation();
  const currentLocation = window.location.pathname;
  const additionalData = {
    prev_page: window.last || lastLocation ? splitURL(lastLocation.pathname) : '',
    page_name: window.landing || splitURL(currentLocation),
  };
  updateGlobalTracker(additionalData);
  window.onpopstate = () => {
    if (process.env.REACT_APP_COUNTRY === 'CO') {
      track(BACK_CLICK, {
        device_type: isMobile() ? 'mobile web' : 'web',
        prv_page: window.last || lastLocation ? splitURL(lastLocation.pathname) : '',
        page_name: window.landing || currentLocation ? splitURL(currentLocation) : '',
        option_back_type: window.isHABack ? 'Home Activator' : 'Browser',
      });
      window.isHABack = false;
      window.landing = '';
      window.last = '';
    }
  };
  return <span></span>;
};

export { LocationLogger };
