import { svAPI } from '../../constants/api';
import { HN_TEXTS } from '../../constants'
import fetchApi from '../../services/fetch';
import { 
    track, 
    generateServerResponseError, 
    generateMessageDisplayedSegment, 
    updateGlobalTracker 
} from 'utils/segmenTracker';
import { 
    ACTIVATION, 
    SERVER_RESPONSE_FAILED, 
    MESSAGE_DISPLAYED,
    OTP_CHANNEL_SMS,
    OTP_FAILED,
    OTP_CHANNEL_EMAIL,
} from 'constants/segmentEvents'


const initialState = {
    otpStatus: {
        emailVerified: false,
        phoneVerified: false
    },
    isRegistered: false
};

export const registration = {
    state: { ...initialState },
    reducers: {
        updateStatus(state, payload) {
            return {
                ...state,
                otpStatus: { ...state.otpStatus, ...payload }
            };
        },
        updateRegistration(state,payload) {
          return {
            ...state,
            isRegistered: payload
          }
        },
        clearStatus() {
            return { ...initialState }
        }
    },
    effects: dispatch => {
        return {
            async getOTP({callback, type, ...params }) {
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: "POST",
                    url: svAPI.getOTP,
                    data: { ...params }
                })
                    .then(() => {
                        dispatch.apiStatus.setProcessing(false);
                        this.updateRegistration(true)
                        this.updateStatus({ received: true })
                        callback && callback();
                    })
                    .catch(error => {
                        dispatch.apiStatus.setRequestFailed({ ...error });
                        track(OTP_FAILED, {otp_channel: OTP_CHANNEL_SMS})
                        track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, `Verify ${type}`, svAPI.getOTP, 'Get OTP API' )})
                        track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Get OTP API')})
                    });
            },
            async verifyOTP({ callback, type, ...params }) {
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: "POST",
                    url: svAPI.validateOTP,
                    data: { ...params }
                })
                    .then((response) => {
                        const { data: {  action = '' } = {} } = response;
                        if(action.toLowerCase() === HN_TEXTS.HN_OTP_VALIDATION_SUCCESS.toLowerCase()) {
                            this.updateStatus({ verified: true })
                            if(type === 'phone') {
                                updateGlobalTracker({'phone OTP status': 'verified'});
                                this.updateStatus({ phoneVerified: true })
                              }
                              else {
                                updateGlobalTracker({'email OTP status': 'verified'})
                              }
                            callback && callback();
                            return
                        }
                        const otpChannel = type === 'phone' ? OTP_CHANNEL_SMS : OTP_CHANNEL_EMAIL;
                        track(OTP_FAILED, {otp_channel: otpChannel})
                       dispatch.apiStatus.setRequestFailed({ message: 'Tu código es incorrecto, inténtalo de nuevo' });
                    })
                    .catch(error => {
                        const otpChannel = type === 'phone' ? OTP_CHANNEL_SMS : OTP_CHANNEL_EMAIL;
                        dispatch.apiStatus.setRequestFailed({ message: 'Tu código es incorrecto, inténtalo de nuevo' });
                        track(OTP_FAILED, {otp_channel: otpChannel})
                        track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, `Verify ${type}`, svAPI.validateOTP, 'Verify OTP API' )})
                        track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Verify OTP API')})
                    });
            },
            async emailChecker({ email }) {
                dispatch.apiStatus.setProcessing(true);
                return fetchApi({
                    method: "GET",
                    url: svAPI.emailChecker,
                    params: { email }
                })
                    .then((res) => {
                        dispatch.apiStatus.setProcessing(false);
                        return Promise.resolve({res: res?.data, success: res?.data?.status === "passed" });
                    })
                    .catch(error => {
                        dispatch.apiStatus.setRequestFailed({ ...error });
                        track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, `Email checker API`, svAPI.emailChecker, 'Email checker API' )})
                        track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Email checker API')})
                        return Promise.resolve({ success: false })
                    });
            },
        };
    }
}

