export const appColors = {
    landingBg: '#00377B',
    landingBgBlack: '#000000',
    landingBgBlack2: '#00000A',
    landingBgGrey: '#eee',
    landingBgWhite: '#fff',
    errorBorder: '#f44336',
    addressInput: '#8ca0be',
    palette: {
        black: '#000000',
        teal: '#00C8FF',
        matterHorn: '#565656',
        white: '#fff',
        lightBlue:'#00C8FF',
    },
    textColorBlue: '#00377B',
    textColorGrey: '#565656',
    green: '#37C846',
    yellow: '#FFBE00',
    white: '#FFF',
    warning: '#FFAA32',
    red: '#F01E46',
    blueGradient: 'linear-gradient(180deg, #2896FF 0%, #0D2974 100%)',
    errorRed: '#FF000A',
    packageWhite:'#FAFAFA',
    secondaryCyan: '#00C8FF',
    stepperGrey: '#e4e4e4',
    textColorDarkBlue: '#00377D',
    linkText: '#00B8FF',
    textColorDarkGrey: '#292B34',
    textColorGrey1: '#787878',
    textColorGrey2: '#00000A',
    emerald: '#3264DC',
    brandTigoPrimary: '#00377D',
    lightGrey: '#787878',
    border: '#DCDCDC',
    grayText: '#555',
    midnightBlue: '#00377DE6',
    extLinkBoxShadow: '#00000024',
    blueGradientNi: 'linear-gradient(180deg, #2896FF 0%, #00377D 100%)',
    gradientPY: 'transparent linear-gradient(180deg, #2384E9 0%, #0D2974 100%)',
    moreInfoArrowColor: '#5656564d',
    orange: '#FF5733 ',
    purple: '#A020F0',
    statusBlueText: '#00f',
}
