
export const HN_CANCEL_REASONS = [
    {
        label : '',
        id : ''
    },
  {
    label: 'Información principal de cliente incorrecta.',
    id: 1,
  },
  {
    label: 'Los servicios ingresado no son los ofrecidos al cliente.',
    id: 2,
  },
  {
    label: 'Cliente cambió de opinión, ya no desea servicio.',
    id: 3,
  },
  {
    label: 'Cliente solicita más información.',
    id: 4,
  },
];