const initialState = {
  data: {
    isProcessing: false,
    has: false
  }
}

export const apiStatus = {
  state: { ...initialState },
  reducers: {
    setProcessing (state, payload) {
      return {
        ...state,
        data: { ...state.data, isProcessing: payload, has: false }
      }
    },

    setRequestFailed (state, payload) {
      return {
        ...state,
        data: {
          ...state.data,
          isProcessing: false,
          has: true,
          ...payload
        }
      }
    },
    clearError(state, payload) {
      return {
        ...initialState
      }
    }
  }
}
