import { COUNTRIES_GT } from 'constants/gt/countries_GT';
import { GT_DEPARTMENTS } from 'constants/gt/departments_GT';
import { GT_MUNCIPALITIES } from 'constants/gt/muncipalities_GT';
import { GT_PAISEEMISSION } from 'constants/gt/paiseemission_GT';
import { get } from 'lodash';

export const convertGtSalesStatusToESLocale = (status) => {
    switch (status) {
      case "RECHAZADA":
      case "FINALIZADA":
        return status.toLowerCase();
      case "ASISTIDA":
        return ("EN VALIDACION".toLowerCase());
      case "FINALIZADO":
        return ("INGRESO EXITOSO".toLowerCase());
      case "REINGRESO":
      case "REENVIO":
        return ("REINGRESAR".toLowerCase());
      case "serviceAddress":
      case "customerInfo":
      case "offer":
      case "appointment":
      case "address":
      case "created":
      case "contactInfo":
      case "geographicLocation":
      case "ERRORDOCUMENTO":
        return ("INCOMPLETA".toLowerCase());
      case "GENERADA": 
        return (status.toLowerCase());
      case "ANULADA":
        return status.toLowerCase();
      default:
        return ("PENDIENTE".toLowerCase());
    }
};

export const gtDepartmentListByName = () => {
    return GT_DEPARTMENTS.map((department) => department.state);
};

export const gtMunicipioListByName = () => {
    return GT_MUNCIPALITIES.map((muncipality) => muncipality.town);
    };
  
export const getPaiseEmission = ()=>{
    return GT_PAISEEMISSION.map((paise)=>paise.DESC)
}
  
export const getDepartment = (departmento)=>{
    return GT_DEPARTMENTS.find(x=>x.state === departmento)
}

export const getMuncip = (muncipo)=>{
    return GT_MUNCIPALITIES.find(x=>x.town === muncipo)
}

export const gtCountriesListByName = () => {
    return COUNTRIES_GT.map((country) => country.name);
};
  
export const getGtCountryCodeFromList = (selected) => {
    const selectedCountry = COUNTRIES_GT.find(
      (country) => country.name === selected
    );
    return selectedCountry?.code;
};

export const filterPackagesGT = (packages, type) => {
    switch (type.toUpperCase()) {
      case 'PAQUETE':
        return packages.filter(pkg => pkg.type.toUpperCase() === 'BUNDLE')
      case 'TV':
        return packages.filter(pkg => pkg.type.toUpperCase() === 'TV')
      case 'INTERNET':
        return packages.filter(pkg => pkg.type.toUpperCase() === 'BBI')
      default:
        return packages
    }
}

export const getProductPriceGT = (pkg, key = 'pricesList', currency = 'Q') => {
    const pricesList = pkg[key] || [];
    const currencyObj = pricesList.find(listItem => listItem.price.currency === currency);
    if (currencyObj) {
      const { price: { taxIncludedAmount, taxes } } = currencyObj;
      return { taxIncludedAmount, taxes, priceWithoutTax: (Number(taxIncludedAmount) - Number(taxes)).toFixed(2) }
    }
    return { taxIncludedAmount: '0.00', taxes: '0.00', priceWithoutTax: '0.00' }
}

export const getAddonDetailsGT = pkg => {
    const descriptionEntries = get(pkg, 'commercialEnrichmentsList[0].description.entries', [])
    const nameEntries = get(pkg, 'commercialEnrichmentsList[0].names.entries', [])
    if (descriptionEntries.length) {
      const { value: description } = descriptionEntries.find(entry => entry.key === 'short-description') || {};
      const { value: planType } = nameEntries.find(entry => entry.key === 'planType') || { value: '' };
      const showCounter = planType ? planType.toLowerCase() === 'equipment' : false
      return { description, name: description, showCounter }
    }
    return { name: '', description: '', type: '' }
}