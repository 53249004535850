
// HN

export const HN_DEPARTMENTS = [
    {
        "CODIGO_DEPTO": "001",
        "DEPARTAMENTO": "ATLANTIDA"
    },
    {
        "CODIGO_DEPTO": "002",
        "DEPARTAMENTO": "COLON"
    },
    {
        "CODIGO_DEPTO": "003",
        "DEPARTAMENTO": "COMAYAGUA"
    },
    {
        "CODIGO_DEPTO": "004",
        "DEPARTAMENTO": "COPAN"
    },
    {
        "CODIGO_DEPTO": "005",
        "DEPARTAMENTO": "CORTES"
    },
    {
        "CODIGO_DEPTO": "006",
        "DEPARTAMENTO": "CHOLUTECA"
    },
    {
        "CODIGO_DEPTO": "007",
        "DEPARTAMENTO": "EL PARAISO"
    },
    {
        "CODIGO_DEPTO": "008",
        "DEPARTAMENTO": "FRANCISCO MORAZAN"
    },
    {
        "CODIGO_DEPTO": "009",
        "DEPARTAMENTO": "GRACIAS A DIOS"
    },
    {
        "CODIGO_DEPTO": "010",
        "DEPARTAMENTO": "INTIBUCA"
    },
    {
        "CODIGO_DEPTO": "011",
        "DEPARTAMENTO": "ISLAS DE LA BAHIA"
    },
    {
        "CODIGO_DEPTO": "012",
        "DEPARTAMENTO": "LA PAZ"
    },
    {
        "CODIGO_DEPTO": "013",
        "DEPARTAMENTO": "LEMPIRA"
    },
    {
        "CODIGO_DEPTO": "014",
        "DEPARTAMENTO": "OCOTEPEQUE"
    },
    {
        "CODIGO_DEPTO": "015",
        "DEPARTAMENTO": "OLANCHO"
    },
    {
        "CODIGO_DEPTO": "016",
        "DEPARTAMENTO": "SANTA BARBARA"
    },
    {
        "CODIGO_DEPTO": "017",
        "DEPARTAMENTO": "VALLE"
    },
    {
        "CODIGO_DEPTO": "018",
        "DEPARTAMENTO": "YORO"
    }
];