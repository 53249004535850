import styled from 'styled-components';

export const Loader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1310;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-tap-highlight-color: transparent;
`;

export const Toast = styled.div`
  width: 100%;
  .toast-wrp {
    max-width: 100%;
    margin: 0;
    z-index: 1301;
    top: ${(props) => (props.accordian ? '0px' : '60px')}
  }
`;
