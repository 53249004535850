export const COUNTRIES_GT = [
  {
    code: "1",
    name: "GUATEMALTECO"
  },
  {
    code: "3",
    name: "ALEMAN"
  },
  {
    code: "5",
    name: "ARABE"
  },
  {
    code: "7",
    name: "ARGENTINO"
  },
  {
    code: "9",
    name: "AUSTRALIANO"
  },
  {
    code: "11",
    name: "AUSTRIACO"
  },
  {
    code: "13",
    name: "BELGA"
  },
  {
    code: "15",
    name: "BOLIVIANO"
  },
  {
    code: "17",
    name: "BRASILEÑO"
  },
  {
    code: "19",
    name: "BRITANICO"
  },
  {
    code: "21",
    name: "BULGARO"
  },
  {
    code: "23",
    name: "CANADIENSE"
  },
  {
    code: "25",
    name: "CHECO"
  },
  {
    code: "27",
    name: "CHILENO"
  },
  {
    code: "29",
    name: "CHINO"
  },
  {
    code: "31",
    name: "COLOMBIANO"
  },
  {
    code: "33",
    name: "COREANO"
  },
  {
    code: "35",
    name: "COSTARRICENSE"
  },
  {
    code: "37",
    name: "CUBANO"
  },
  {
    code: "39",
    name: "DANES"
  },
  {
    code: "41",
    name: "DOMINICANO"
  },
  {
    code: "43",
    name: "EGIPCIO"
  },
  {
    code: "45",
    name: "ESPAÑOL"
  },
  {
    code: "47",
    name: "ESTADOUNIDENSE"
  },
  {
    code: "49",
    name: "FRANCES"
  },
  {
    code: "51",
    name: "GRIEGO"
  },
  {
    code: "53",
    name: "PERUANO"
  },
  {
    code: "55",
    name: "HAITIANO"
  },
  {
    code: "57",
    name: "HINDU"
  },
  {
    code: "59",
    name: "HOLANDES"
  },
  {
    code: "61",
    name: "HONDUREÑO"
  },
  {
    code: "63",
    name: "HONG KONG"
  },
  {
    code: "65",
    name: "ISLEÑO"
  },
  {
    code: "67",
    name: "ISRAELITA"
  },
  {
    code: "69",
    name: "ITALIANO"
  },
  {
    code: "71",
    name: "JAMAIQUINO"
  },
  {
    code: "73",
    name: "JAPONES"
  },
  {
    code: "75",
    name: "LUXEMBURGUES"
  },
  {
    code: "77",
    name: "MEXICANO"
  },
  {
    code: "79",
    name: "MONAQUENSE"
  },
  {
    code: "81",
    name: "NEOZELANDES"
  },
  {
    code: "83",
    name: "NICARAGUENSE"
  },
  {
    code: "85",
    name: "NORUEGO"
  },
  {
    code: "87",
    name: "PANAMEÑO"
  },
  {
    code: "89",
    name: "PARAGUAYO"
  },
  {
    code: "91",
    name: "POLACO"
  },
  {
    code: "93",
    name: "PORTUGUES"
  },
  {
    code: "95",
    name: "RUMANO"
  },
  {
    code: "97",
    name: "RUSO"
  },
  {
    code: "2",
    name: "SALVADOREÑO"
  },
  {
    code: "101",
    name: "SUDAFRICANO"
  },
  {
    code: "103",
    name: "SUECO"
  },
  {
    code: "105",
    name: "SUIZO"
  },
  {
    code: "107",
    name: "TAIWANES"
  },
  {
    code: "109",
    name: "URUGUAYO"
  },
  {
    code: "111",
    name: "VENEZOLANO"
  },
  {
    code: "112",
    name: "PUERTORRIQUEÑO"
  },
  {
    code: "113",
    name: "ECUATORIANO"
  },
  {
    code: "114",
    name: "LIBANES"
  }
]