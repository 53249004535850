import { getCountry } from '../utils/utils';

const country = getCountry();

const assistedCountries = ['SV', 'HN', 'GT', 'PY', 'NI'];

const getNetCodeURL = (ctry) => {
  if (assistedCountries.includes(ctry)) {
    return 'https://id.tigo.com';
  } else {
    return 'https://prod.api.tigo.com';
  }
};

const getPreprodBaseUrl = () => {
  if (['GT', 'BO', 'SV'].includes(country)) {
    return `https://ha-tigo-com-${country.toLowerCase()}-uat.tigocloud.net`;
  } else if (['CR'].includes(country)) {
    return `https://ha-uat.tigo.${country.toLowerCase()}`;
  } else {
    return `https://ha-uat.tigo.com.${country.toLowerCase()}`;
  }
};

const getBaseUrl = (type = '') => {
  if (['CR'].includes(country)) {
    return `https://ha${type}.tigo.${country.toLowerCase()}`;
  } else {
    if (['PY'].includes(country)) {
      if (type) {
        return `https://ha${type}.tigo.com.${country.toLowerCase()}`;
      } else {
        return `https://ah.tigo.com.${country.toLowerCase()}`;
      }
    }
    return `https://ha${type}.tigo.com.${country.toLowerCase()}`;
  }
};

const getFpUrl = () => {
  if (['CR'].includes(country)) return 'https://ha-fp.tigo.cr';
  else return `https://ha-fp.tigo.com.${country.toLowerCase()}`;
};

const development = {
  apiURL: getBaseUrl('-dev'),
  netCodeURL: 'https://test.id.tigo.com',
  redirectURL: `${getBaseUrl('-dev')}/redirect`,
  codeVerifier: process.env[`REACT_APP_${country}_CODE_VERIFIER`],
  clientId: process.env[`REACT_APP_${country}_CLIENT_ID`],
  fpUrl: getFpUrl(),
  pgUrl: 'https://pagos-uat.tigocloud.net/checkout',
};

const staging = {
  apiURL: getBaseUrl('-stg'),
  netCodeURL: 'https://test.id.tigo.com',
  redirectURL: `${getBaseUrl('-stg')}/redirect`,
  codeVerifier: process.env[`REACT_APP_${country}_CODE_VERIFIER`],
  clientId: process.env[`REACT_APP_${country}_CLIENT_ID`],
  fpUrl: getFpUrl(),
  pgUrl: 'https://pagos-uat.tigocloud.net/checkout',
};

const production = {
  apiURL: getBaseUrl(),
  netCodeURL: getNetCodeURL(country),
  redirectURL: `${getBaseUrl()}/redirect`,
  codeVerifier: process.env[`REACT_APP_${country}_CODE_VERIFIER`],
  clientId: process.env[`REACT_APP_${country}_CLIENT_ID`],
  fpUrl: getFpUrl(),
  pgUrl: 'https://pagos-uat.tigocloud.net/checkout',
};

const domain = ['CO', 'CR'].includes(country) ? '-uat' : '-pp';

const preproduction = {
  apiURL: getPreprodBaseUrl(),
  netCodeURL: getNetCodeURL(country),
  redirectURL: `${getPreprodBaseUrl()}/redirect`,
  codeVerifier: process.env[`REACT_APP_${country}_CODE_VERIFIER`],
  clientId: process.env[`REACT_APP_${country}_CLIENT_ID`],
  fpUrl: getFpUrl(),
};

const getCurrentEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'development':
      return development;
    case 'staging':
      return staging;
    case 'production':
      return production;
    case 'preproduction':
      return preproduction;
    default:
      return development;
  }
};

const env = getCurrentEnv();

export const appConfig = {
  ...env,
};
