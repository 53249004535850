import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import { 
  track, 
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
} from 'utils/segmenTracker';
import {
  SERVER_RESPONSE_FAILED,
  ACTIVATION,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";

const initialState = {
  data: {
    department: '',
    city: '',
    suburb: '',
    street: '',
    house: '',
    email: '',
    telephone: '',
    node: { id: '' },
  },
  locations: {
    department: [],
    city: [],
    suburb: [],
  },
  coverageInfo: [],
  error: {
    department: null,
    city: null,
    suburb: null,
  },
  customerResponse: null,
};

export const inputAddress = {
  state: { ...initialState },
  reducers: {
    updateAddress (state, payload) {
      return {
        ...state,
        data: { ...state.data, ...payload },
      };
    },
    updateLocation (state, payload) {
      return { ...state, locations: { ...state.locations, ...payload } };
    },
    updateCoverageInfo (state, payload) {
      return {
        ...state,
        coverageInfo: payload,
      };
    },
    updateError (state, payload) {
      return {
        ...state,
        error: { ...state.error, ...payload },
      };
    },
    clearInputError (state, payload) {
      return {
        ...state,
        error: { ...state.error, ...payload },
      };
    },
    clearAddressData (state) {
      return {
        ...initialState,
      };
    },
    updateNode (state, payload) {
      const node = state.coverageInfo.find(c => c.id === payload);
      return {
        ...state,
        data: { ...state.data, node },
      };
    },
    updateCustomerResponse (state, payload) {
      return {
        ...state,
        customerResponse: payload,
      };
    },
    clearLocation(state) {
      return {
        ...state,
        locations: {
          department: state.locations.department,
          city: [''],
          suburb: ['']
        }
      }
    },
    clearNode(state) {
      return {
        ...state,
        coverageInfo: []
      }
    }
    
  },

  effects: dispatch => {
    return {
      async getDepartments () {
        dispatch.inputAddress.clearNode()
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: 'get',
          url: svAPI.departments, 
        })
          .then(response => {
            dispatch.inputAddress.updateAddress({ city: '', suburb: '' })
            dispatch.inputAddress.updateLocation({
              department: response.data,
              city: [],
              suburb: [],
            });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.inputAddress.updateLocation({
              city: [],
              suburb: [],
            })
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.message.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Input Address', svAPI.departments, 'Departments API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Departments API')})
          });
      },
      async getCity (deparment) {
        dispatch.apiStatus.setProcessing(true);
        dispatch.inputAddress.clearNode()
        fetchApi({
          method: 'get',
          url: svAPI.city,
          params: { deparment }
        })
          .then(response => {
            dispatch.inputAddress.clearLocation()
            dispatch.inputAddress.updateAddress({ suburb: '', city: '' })
            dispatch.inputAddress.updateLocation({
              city: response.data,
              suburb: [],
            });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.inputAddress.updateLocation({
              city: [],
              suburb: [],
            })
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.message.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Input Address', svAPI.city, 'City API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'City API')})
          });
      },
      async getSuburb(data) {
        dispatch.apiStatus.setProcessing(true);
        dispatch.inputAddress.clearNode()
        fetchApi({
          method: 'post',
          url: svAPI.suburb,
          data,
        })
          .then(response => {
            dispatch.inputAddress.updateAddress({ suburb: '' })
            dispatch.inputAddress.updateLocation({ suburb: response.data });
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.message.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Input Address', svAPI.suburb, 'Suburb API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Suburb API')})
          });
      },
      async getCoverage (data) {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: 'post',
          url: svAPI.coverage,
          data,
        })
          .then(response => {
            dispatch.inputAddress.updateCoverageInfo(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            if(error.errorCode !== 404) {
              if (typeof error.message === 'object') {
                dispatch.apiStatus.setRequestFailed({
                  errorCode: error.errorCode,
                  message: error.message.message,
                });
              } else {
                dispatch.apiStatus.setRequestFailed({ ...error });
              }
          }
          else {
            dispatch.apiStatus.setProcessing(false);
          }
          track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Input Address', svAPI.coverage, 'Coverage API' )})
          track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Coverage API')})
          });
      },
      async saveCustomerInfo (data) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'post',
          url: svAPI.customerContact,
          data,
        })
          .then(response => {
            dispatch.inputAddress.updateCustomerResponse(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            if (typeof error.message === 'object') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.message.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'Coverage', svAPI.customerContact, 'Customer Contact API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Customer Contact API')})
          });
      },
    };
  },
};