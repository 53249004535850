import { getDevice, getOS } from '../../deviceInfo';

const { vendor, model, type } = getDevice();
const { name } = getOS();

export const defaultTrackValuesNI = {
    authentication_method: "tigoId",
    authentication_provider: "msisdn",
    connection_type: 'Mobile',
    // page_name: null,
    // prev_page: null,
    segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
    msisdn: '',
    version: process.env.REACT_APP_SEGMENT_VERSION,
    business_category: '',
    lead_id: "",
    poa_id: '', 
    poa_sales_channel: '',
    carrier: '',
    poa_first_name: '',
    poa_last_name: '',
    poa_at_home: '',
    device_id: window?.MediaDeviceInfo?.deviceId || '',
    device_manufacturer: vendor || '',
    device_model: model || '',
    device_height: window.innerHeight,
    device_platform: name || '',
    device_type: type,
    ip: '',
    msisdn_anon_user: '',
    state: '',
    city: '',
    town: '',
    address: '',
    text_content: 'activation',
    home_reference_id: '',
    home_location: '',
    device_location: '',
    product_default: '',
    products: '',
    promotion_name: '',
    plan_price: '',
    tax_amount: '',
    fee_amount: '',
    currency: '$',
    national_id_type: '',
    national_id: '',
    user_name: '',
    email: '',
    expiration_date: '',
    issuance_date: '',
    birth_date: '',
    gender: '',
    email_validation: '',
    id_validation: '',
    msisdn_validation: '',
    linked_msisdns: '',
    reference_added: '',
    terms_and_conditions: '',
    'phone OTP status': '',
    'email OTP status': '',
    visit_date: '',
    visit_time: '',
    visit_type: '',
    photo_capture_front: '',
    photo_capture_rear: '',
    map_zoom_scale: '',
    contract_id: '',
    automatic_payment_enabled: '',
    service_available: '',
    credit_available: '',
    // existing_client: ''
}