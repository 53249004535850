
  
const initialState = {
    data: {
        error: false
    }
  }
  
  export const location = {
    state: { ...initialState },
    reducers: {
      updateLocationError (state, payload) {
        return {
          ...state,
          data: { ...state.data, error: payload }
        }
      },
      clearLocationError() {
        return {
          ...initialState
        }
      }
    }
  }
  