import fetchApi from "../../services/fetch";
import { svAPI } from "../../constants/api";
import { deleteCookie } from "../../utils/utils";
import { pinEncryptKeyPair } from "../../config/rsa";
import { track, updateGlobalTracker, identify } from "../../utils/segmenTracker";
import { 
  ACTIVATION, 
  LOGIN_FAILED,
} from "../../constants/segmentEvents";
import moment from 'moment';

const NodeRSA = require("node-rsa");
const key = new NodeRSA(pinEncryptKeyPair);

const initialState = {
  data: {
  },
  salesPerson: {
  },
  promotionDetails: {
  },
  authorization: [],
  editFlow: false,
  referenceEdit: false
};

export const auth = {
  state: { ...initialState },
  reducers: {
    updateAuthData(state, payload) {
      return {
        ...state,
        data: { ...state.data, ...payload }
      };
    },
    clearPIN(state, payload) {
      return {
        ...state,
        data: { ...state.data, pin: "" }
      };
    },
    updateSalesPersonData(state, payload) {
      return {
        ...state,
        salesPerson: { ...state.salesPerson, ...payload }
      };
    },
    updatePromotionDetails(state, payload) {
      return {
        ...state,
        promotionDetails: payload
      };
    },
    updateAuthorization(state, payload) {
      return {
        ...state,
        authorization: payload
      };
    },
    clearAuthorization(state) {
      return {
        ...state,
        authorization: []
      }
    },
    isEdit(state, payload) {  
        return {...state, editFlow: payload}
    },
    isReferenceEdit(state, payload) {  
      return {...state, referenceEdit: payload}
  }
  },

  effects: dispatch => {
    return {
      async authenticate (params) {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "POST",
          url: svAPI.accessToken,
          data: {
            code: params.code,
            country: 'SV',
          },
        })
          .then(response => {
            dispatch.auth.updateAuthData(response.data);
            const { poa_msisdn, uniqueIdentifier } = response.data;
            localStorage.setItem('msisdn', poa_msisdn);
            const userId = uniqueIdentifier;
            identify(userId, {
              msisdn: poa_msisdn,
              country: 'SV'
            })
            updateGlobalTracker({msisdn: poa_msisdn, prev_page: "Splash screen", page_name: 'Inicio'})
            params.navigateTo && params.navigateTo()
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            let additionalData = {
              error_id: error.errorCode,
              error_message: error.message,
              journey: ACTIVATION,
            }
            track(LOGIN_FAILED, additionalData);
          });
      },
      async logout() {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: "POST",
          url: svAPI.logout,
          data: {
            country: "SV"
          }
        })
          .then(() => {
            deleteCookie();
            localStorage.clear()
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            deleteCookie();
            localStorage.clear();
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async getSalesPersonDetails(data) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: "POST",
          url: svAPI.salesPersonDetails + moment().format('YYYY-MM-DDTHH:mm:ss'),
          data: {
            password: key.encrypt(data.pin, "base64")
          }
        })
          .then((response) => {
            dispatch.auth.updateSalesPersonData(response.data)
            const { msisdn } = response.data
            localStorage.setItem('msisdn', msisdn);
            updateGlobalTracker({
              msisdn: msisdn, 
              journey: ACTIVATION,
              poa_id:  response.data.poaSellerCode, 
              poa_first_name:  response.data?.seller?.firstName || '', 
              poa_last_name:  response.data?.seller?.lastName  || '',   
            })
            const code = response.data?.poaSellerCode || ''
            return fetchApi({
              method: "GET",
              url: svAPI.promotionDetails.replace(':salesPersonCode',code)
            })
          })
          .then(response => {
            dispatch.auth.updatePromotionDetails(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            dispatch.auth.clearPIN()
          });
      },
      async clearStore() {
        dispatch({ type: 'RESET_APP'})
      },
      async clearCurrentData() {
        this.clearAuthorization();
        dispatch.termsAndConditions.clearTerms();
        dispatch.scheduleVisit.clearSchedule();
        dispatch.sales.clearSales();
        dispatch.packages.clearPackages();
        dispatch.inputAddress.clearAddressData();
        dispatch.idDetails.clearIdDetails();
        dispatch.documentUpload.clearDocumetDetails();
        dispatch.clientDetails.clearclientDetails();
        dispatch.activation.clearActivation();
        dispatch.captureStatus.clearCaptureStatus();
      }
    };
  }
};
