import moment from 'moment';
import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import { generateMessageDisplayedSegment, generateServerResponseError, track } from 'utils/segmenTracker';
import { SERVER_RESPONSE_FAILED  , ACTIVATION , MESSAGE_DISPLAYED} from 'constants/segmentEvents';

const initialState = {
  duiNumber: '',
  clientInfo: [],
  currentSelectedUser: '',
  clientSubscription: [],
  contractBalance: null,
  clientDetails: {
    cliDocumentType: '',
    cliDocumentNum: '',
    cliLastName: '',
    cliFirstName: '',
    isNamesMatched: '',
    hasDebt: '',
  },
  clientInfoValidateResponse: {},
};

const getDocumentType = (documentName) => {
  switch (documentName) {
    case 'Pasaporte':
      return 'PASAPORTE';
    case 'Carnet de Residente':
      return 'CRES';
    default:
      return documentName;
  }
};

export const clientDetails = {
  state: { ...initialState },
  reducers: {
    updateDUI(state, payload) {
      return {
        ...state,
        duiNumber: payload,
      };
    },
    updateClientInfo(state, payload) {
      return {
        ...state,
        clientDetails: payload,
      };
    },
    updateClientInfoResponse(state, payload) {
      return {
        ...state,
        clientInfoValidateResponse: payload,
      };
    },
    updateClientDetails(state, payload) {
      return {
        ...state,
        clientInfo: payload,
      };
    },
    clearDUI(state, payload) {
      return {
        ...state,
        duiNumber: '',
      };
    },
    setCurrentSelectedUser(state, payload) {
      return {
        ...state,
        currentSelectedUser: payload,
      };
    },
    updateClientSubscription(state, payload) {
      return {
        ...state,
        clientSubscription: payload,
      };
    },
    updateContractBalance(state, payload) {
      return {
        ...state,
        contractBalance: payload,
      };
    },
    updateClientIncome(state, payload) {
      return {
        ...state,
        clientIncome: payload,
      };
    },
    clearclientDetails() {
      return { ...initialState };
    },
  },

  effects: (dispatch) => {
    return {
      async getClientDetail(duiNumber) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: svAPI.clientDetail.replace('{}', duiNumber),
        })
          .then((response) => {
            dispatch.clientDetails.updateClientDetails(response.data);
            dispatch.apiStatus.setProcessing(false);
            return response;
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            return error;
          });
      },
      async getClientSubscription(accountId) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: svAPI.clientSubscription.replace('{}', accountId),
        })
          .then((response) => {
            dispatch.clientDetails.updateClientSubscription(response.data);
            dispatch.apiStatus.setProcessing(false);
            return response;
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            return error;
          });
      },

      async getClientBalance(accountId) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: svAPI.clientSubscriptionBalance.replace('{}', accountId),
        })
          .then((response) => {
            dispatch.clientDetails.updateContractBalance(response.data);
            dispatch.apiStatus.setProcessing(false);
            return response;
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            return error;
          });
      },

      async clientInfoValiadte(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: `${svAPI.clientInfoValiadte}?identificationID=${
            payload.cliDocumentNum
          }&identificationType=${getDocumentType(payload.cliDocumentType)}&requestDate=${moment()
            .utc()
            .format('YYYY-MM-DDThh:mm:ss')}`,
        })
          .then((response) => {
            dispatch.clientDetails.updateClientInfo({
              ...payload,
              hasDebt: response?.data?.individualsLists?.[0]?.customerAccountLists?.[0]?.hasDebt || '',
            });
            dispatch.clientDetails.updateClientInfoResponse(response.data);
            dispatch.apiStatus.setProcessing(false);
            return response;
          })
          .catch((error) => {
            if (error?.rawResponse?.errors?.[0]?.message !== 'No se encontro documentos para ese cliente') {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error?.rawResponse?.error?.message,
              });
            } else {
              dispatch.apiStatus.setProcessing(false);
            }
            return error;
          });
      },
      async clientIncomeValiadte(payload) {
        dispatch.apiStatus.setProcessing(true);
        return fetchApi({
          method: 'get',
          url: `${svAPI.incomeValiadte}`,
          params: {
            trxType: 'HOME',
            requestDate: moment().format('YYYY-MM-DDTHH:mm:ss.sssZZ'),
            identificationType: payload.cliDocumentType,
            identificationId: payload.cliDocumentNum,
            offerAmount: payload.price,
            offerTaxesAmount: payload.tax,
            customerIncome: payload.income
          }
        })
          .then((response) => {
            payload.handleScenario(response.data);
            dispatch.apiStatus.setProcessing(false);      
            return response;
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({
              errorCode: error.errorCode,
              message: error?.message,
            });
            dispatch.apiStatus.setProcessing(false);
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, `Customer Income`, svAPI.incomeValiadte, 'Income Validate API' )});
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Income Validate API')});
            return error;
          });
      },
    };
  },
};
