import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import models from "./models";
import logger from 'rematch-logger';

const loading = createLoadingPlugin();

const store = init({
  redux: { 
    middlewares: [],
    rootReducers: { 
      RESET_APP: () => undefined,
      RELOAD_DATA: (_, action) => action.payload
    },
  },
  plugins: [loading, process.env.NODE_ENV === 'development' && logger()],
  models
})

export default store;



