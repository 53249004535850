import { getDevice, getOS } from './deviceInfo';

const { vendor, model, type } = getDevice();
const { name } = getOS();

export const globalSegmentsCR = {
  authentication_method: 'tigoId',
  authentication_provider: 'msisdn',
  connection_type: 'Mobile',
  page_name: '',
  prev_page: '',
  segment_source_name: process.env.REACT_APP_SEGMENT_SOURCE_NAME,
  msisdn: '',
  version: process.env.REACT_APP_SEGMENT_VERSION,
  business_category: 'Home',
  lead_id: '',

  poa_sales_channel: '',

  carrier: '',
  device_id: window?.MediaDeviceInfo?.deviceId || '',
  device_manufacturer: vendor || '',
  device_model: model || '',
  device_height: window.innerHeight,
  device_platform: name || '',
  device_type: type,
  ip: '',
  msisdn_anon_user: '',
  state: '',
  city: '',
  town: '',
  address: '',

  product_default: '',
  products: '',
  promotion_name: '',
  plan_price: '',
  poa_at_home: false,

  currency: '₡',
  national_id_type: '',
  national_id: '',
  user_name: '',
  email: '',

  issuance_date: '',

  email_validation: '',
  id_validation: '',
  msisdn_validation: '',

  terms_and_conditions: '',
  'phone OTP status': '',
  'email OTP status': '',
  credit_available: '',
};
