import fetchApi from '../../services/fetch';
import { svAPI } from '../../constants/api';
import {
  SERVER_RESPONSE_FAILED,
  ACTIVATION,
  MESSAGE_DISPLAYED,
} from "../../constants/segmentEvents";
import { 
  track, 
  generateServerResponseError, 
  generateMessageDisplayedSegment, 
} from 'utils/segmenTracker';

const initialState = {
  data: {},
};

export const documentUpload = {
  state: { ...initialState },
  reducers: {
    updateDocumentDetails (state, payload) {
      return { ...state, data: { ...state.data, ...payload } };
    },
    clearDocumetDetails () {
      return { ...initialState };
    },
  },
  effects: dispatch => {
    return {
      async uploadDocument ({ ...params }, state) {
        dispatch.apiStatus.setProcessing(true);
  
        fetchApi({
          method: 'post',
          url: svAPI.frontIdUpload,
          data: params.data,
        })
          .then(response => {
            const {
              data: {
                Payload: { fileName, imageUrl },
              },
            } = response;
            const idDetails = {
              fileName: fileName.substring(0, fileName.lastIndexOf('/')),
              imageUrl,
              count: params.count
            };
            if(state.auth.editFlow) {
              const fileInfo = {
                fileName: fileName.substring(0, fileName.lastIndexOf('/')),
              };
              dispatch.idDetails.updateIdDetails({ ...fileInfo });
            }
            dispatch.documentUpload.updateDocumentDetails({ [params.documentType]: {...idDetails} });
            params.callback && params.callback()
            dispatch.apiStatus.setProcessing(false);
          })
          .catch(error => {
            dispatch.apiStatus.setRequestFailed({ ...error });
            track(SERVER_RESPONSE_FAILED, {...generateServerResponseError(error, ACTIVATION, 'ID scanning', svAPI.frontIdUpload, 'Upload API' )})
            track(MESSAGE_DISPLAYED, {...generateMessageDisplayedSegment(error, ACTIVATION, 'Upload API')})
          });
      },
    };
  },
};
