import styled from 'styled-components';
import { appColors } from 'constants/colors';

export const Notification = styled.div`
  position: fixed;
  max-width: 320px;
  background: ${appColors.white};
  padding: 12px;
  z-index: 100001;
  bottom: 70px;
  right: 20px;
  box-shadow: 0px 2px 7px rgb(0 0 0 / 25%);
  border-radius: 4px;
  max-width: 249px;
  text-align: left;
  cursor: ${({ cursor }) => (cursor ? 'pointer' : 'default')};
  .MuiSvgIcon-root {
    position: absolute;
    top: 9px;
    right: 9px;
    font-size: 18px;
    cursor: pointer;
    color: ${appColors.secondaryCyan};
  }
`;
