export const svAPI = {
  departments: '/v1/tigo/home/sv/activator/departments',
  city: '/v1/tigo/home/sv/activator/municipality',
  suburb: '/v1/tigo/home/sv/activator/states/locations',
  coverage: '/v1/tigo/home/sv/activator/services/coverage',
  accessToken: '/v1/tigo/sv/accesstoken',
  products: '/v1/tigo/home/sv/activator/products',
  customerContact: '/v1/tigo/home/sv/activator/customerContact',
  logout: '/v1/tigo/sv/logout',
  sales: '/v1/tigo/home/sv/activator/leadInfo/XXXMSISDNXXX',
  salesDetail: '/v1/tigo/home/sv/activator/leadInfo/XXXMSISDNXXX/:id',
  clientDetail: '/v1/tigo/home/sv/activator/customers/DUI/{}/accounts',
  clientSubscription: '/v1/tigo/home/sv/portfolio/contracts/{}/products/',
  clientSubscriptionBalance: '/v1/tigo/home/sv/billing/contracts/{}/balance/',
  activation: '/v1/tigo/home/sv/activator/services/activation',
  scheduleDates: '/v1/tigo/home/sv/activator/field-services/dates',
  getTimeSlots: '/v1/tigo/home/sv/activator/field-services/timeslots?requestDate=',
  frontIdUpload: '/v1/tigo/sv/upload',
  rearIdRecognition: '/v1/tigo/sv/uploadAndRecognize',
  frontIdRecognition: '/v1/tigo/sv/recognize',
  getUser: '/v1/tigo/sv/users',
  createUser: '/v1/tigo/sv/users',
  verifyPhoneOTP: '/v1/tigo/sv/users/{}/contact-mediums/phones',
  verifyEmailOTP: '/v1/tigo/sv/users/{}/contact-mediums/emails',
  activationCost: '/v1/tigo/home/sv/activator/field-services/costs',
  salesPersonDetails: '/v1/tigo/home/sv/activator/agent/credentials?requestDate=',
  termsAndConditions: '/v1/tigo/sv/users/terms-and-conditions',
  validateDUI: '/v1/tigo/selfcare/sales/sv/subscribers/{}/eligibilities/eligibility-decisions',
  validateOTP: '/v1/tigo/sv/otp/validate',
  getOTP: '/v1/tigo/sv/otp',
  clientInfoValiadte: '/v1/tigo/home/sv/activator/individuals',
  incomeValiadte: '/v1/tigo/home/sv/activator/creditscore/nonclient',
  promotionDetails: '/v1/tigo/home/sv/activator/promotion/:salesPersonCode',
  emailChecker: '/v1/tigo/home/sv/activator/bulkemailchecker'
};

export const niAPI = {
  niCoverage: '/v1/tigo/home/ni/activator/offers/coverage',
  accessToken: '/v1/tigo/ni/accesstoken',
  accessToken_ss: '/v1/tigo/ni/unassisted/accesstoken',
  logout: '/v1/tigo/ni/logout',
  maintenance: '/v1/tigo/ni/maintenance',
  getClientsById: '/v1/tigo/masteraccount/ni/party/cid/:id/accounts',
  getClientHomeDebts: 'v1/tigo/home/ni/billing/contracts/:id/balance',
  getClientMobileDebts: '/v1/tigo/mobile/ni/billing/:id/balance',
  createLead: '/v1/tigo/home/ni/activator/orders',
  updateLead: '/v1/tigo/home/ni/activator/orders/:id',
  salesPersonDetails: '/v1/tigo/sv/activator/salesforces/XXXMSISDNXXX/login',
  upload: '/v1/tigo/ni/upload',
  getLocations: '/v1/tigo/home/ni/activator/locations/neighborhoods',
  getInstallationDates: '/v1/tigo/home/ni/activator/field-services/dates/clients/:id',
  otp: '/v1/tigo/ni/otp',
  validateOTP: '/v1/tigo/ni/otp/validate',
  creditProfile: '/v1/tigo/ni/customermanagment/customer/:type/:id/creditProfile',
  offers: '/v1/tigo/home/ni/activator/offers',
  getSalesPersonLeads: '/v1/tigo/home/ni/activator/orders/:type/:id',
  updateDAR: '/v1/tigo/dar/trusted/async/users',
  emailConfirmation: '/v1/tigo/eventbroker/events',
  removeFile: '/v1/tigo/ni/delete',
};

export const coAPI = {
  accessToken: '/v1/tigo/co/accesstoken',
  register: '/v1/tigo/datacredito/co/selfcare/information',
  user: '/v1/tigo/co/users',
  verifyPhoneOTP: '/v1/tigo/co/users/{}/contact-mediums/phones',
  verifyEmailOTP: '/v1/tigo/co/users/{}/contact-mediums/emails',
  getSocialStratus: '/v1/tigo/home/co/activator/locations/addresses',
  packages: '/v1/tigo/home/co/activator/offers',
  zendesk: '/v2/tigo/zendesk/tickets',
  zendeskUpdate: '/v2/tigo/zendesk/tickets/many',
  coverage: '/v1/tigo/home/co/activator/offers/coverage',
  otp: '/v1/tigo/co/otp',
  verifyOtp: '/v1/tigo/co/otp/validate',
  inputAutoComplete: '/v1/tigo/home/co/activator/locations/addresses/autocomplete',
  geoReference: '/v1/tigo/home/co/activator/locations/address/georeferenced',
  addressInputMap: '/v1/tigo/home/co/activator/locations/addresses/geocoding',
  validateClient: '/v1/tigo/home/co/portfolio/customers/info',
  verifySelfcareOtp: '/v1/tigo/datacredito/co/selfcare/validar',
  varifySefcareInitialize: '/v1/tigo/datacredito/co/selfcare/otp/initialize',
  generateOtp: '/v1/tigo/datacredito/co/selfcare/otp/generate',
  varifyOtpCode: '/v1/tigo/datacredito/co/selfcare/otp/verify',
  securityQuestions: '/v1/tigo/datacredito/co/selfcare/preguntas',
  saveSecurityQs: '/v1/tigo/datacredito/co/selfcare/verificar',
  nonClient: '/v1/tigo/home/co/activator/creditScore/nonClient',
  nonClientScore: '/v1/tigo/home/co/activator/creditScore/nonClient/score',
  creditValidation: '/v1/tigo/home/co/activator/offers/validation',
  creditValidationResult: '/v1/tigo/home/co/activator/offers/validation/result',
  initiateDate: '/v1/tigo/home/co/activator/field-services/timeslots/dates',
  timeslots: '/v1/tigo/home/co/activator/field-services/timeslots/dates/getDate',
  reserveSlot: '/v1/tigo/home/co/activator/field-services/installations/timeslots/dates/schedule',
  getSlot: '/v1/tigo/home/co/activator/field-services/installations/timeslots/dates/getDate',
  activateService: '/v1/tigo/home/co/activator/orders',
  activateServiceStatus: '/v1/tigo/home/co/activator/orders/status',
  contract: 'v1/tigo/co/documents',
  contractUpload: '/v1/tigo/co/uploadSign',
  maintenance: '/v1/tigo/co/maintenance ',
  brazeEvents: '/v1/tigo/eventbroker/events',
  moduleStatus: '/v1/tigo/module/:moduleCode/status',
  moduleStatusAll: '/v1/tigo/module/index',
  dar: '/v1/tigo/dar/trusted/async/users',
  contentful: '/v1/tigo/contentful/entries',
  orders: '/v2/tigo/home/co/activator/orders',
};

export const hnAPI = {
  accessToken: '/v1/tigo/accesstoken',
  logout: '/v1/tigo/logout',
  upload: '/v1/tigo/upload',
  ocr: '/v1/tigo/uploadAndRecognize',
  recognize: '/v1/tigo/recognize',
  agreement: '/v1/tigo/home/hn/activator/agreements',
  coverage: '/v1/tigo/home/hn/activator/offers/coverage',
  offers: '/v1/tigo/home/hn/activator/offers',
  idValidation: '/v1/tigo/selfcare/sales/hn/subscribers/{}/eligibilities/eligibility-decisions',
  otp: '/v1/tigo/otp',
  validateOTP: '/v1/tigo/otp/validate',
  activation: '/v1/tigo/home/hn/activator/orders',
  orders: '/v1/tigo/home/hn/activator/orders/status',
  salesReport: '/v1/tigo/home/hn/activator/orders/leads',
  localization: '/v1/tigo/home/hn/activator/locations/localities',
  timeSlots: '/v1/tigo/home/hn/activator/field-services/timeslots',
  installationOrder: '/v1/tigo/home/hn/activator/orders/{}/appointment',
  mapReference: '/v1/tigo/home/hn/activator/offers/coverage/references',
  emailSms: '/v1/tigo/eventbroker/events',
  callDar: '/v1/tigo/dar/trusted/async/users',
  paymentCondition: '/v1/tigo/home/hn/activator/payments/conditions/customers/',
  clientInfoValiadte: '/v1/tigo/home/hn/activator/individuals',
  checkForMaintenence: '/v1/tigo/maintenance',
};
export const pyAPI = {
  ...hnAPI,
  salesReport: '/v1/tigo/home/py/activator/orders/leads',
  getClientInfoFromDB: '/v1/tigo/sales/py/poa/subscribers/ids/:id',
  createLead: '/v1/tigo/home/py/activator/orders',
  validateClient: '/v1/tigo/home/py/activator/offers/validation/:basketID',
  getGeographicLocation: '/v1/tigo/home/py/activator/location/geographicAddresses',
  updateLead: '/v1/tigo/home/py/activator/orders/:id',
  packages: '/v1/tigo/home/py/activator/offers/categories/:categoriesId',
  packagesByCatalog: '/v1/tigo/home/py/activator/offers/catalog',
  sendOtp: '/v1/tigo/otp',
  verifyOtp: '/v1/tigo/otp/validate',
  salesReportById: '/v1/tigo/home/py/activator/orders/leads/:leadID',
  scheduleDates: '/v1/tigo/home/py/activator/field-services/dates',
};
export const gtAPI = {
  ...hnAPI,
  getClientsById: '/v1/tigo/sales/gt/poa/subscribers/:id/bss',
  getClientDebts: '/v2/tigo/mobile/gt/billing/subscribers/:id/debtStatus',
  createLead: '/v1/tigo/home/gt/activator/orders',
  getAddressList: '/v1/tigo/home/gt/activator/geographicLocation/geographicAddresses',
  updateLead: '/v1/tigo/home/gt/activator/orders/ORDER_ID',
  offers: '/v1/tigo/home/gt/activator/offers',
  timeSlots: '/v1/tigo/home/gt/activator/field-services/timeslots',
  salesReport: '/v1/tigo/home/gt/activator/orders/leads',
  salesReportById: '/v1/tigo/home/gt/activator/orders/leads/:id',
  appointmentById: '/v1/tigo/home/gt/cases/contracts/0/appointments/:id/1',
  currentPlansByMsisdn: '/v1/tigo/rest/GetClientAccountGeneralInfoVL1/GT/clients',
  currentAddonsByContractId: '/v1/tigo/mobile/gt/vas/subscribers/:id/subscriptions',
  currentAmountDueByContractId: '/v1/tigo/home/gt/portfolio/contracts/:id/plan/bundle',
  productCodeByPlanId: '/v1/tigo/home/gt/portfolio/plans/:id',
  recommendedAddonsByContractId: '/v1/tigo/mobile/gt/vas/subscribers/:id/products?channelId=70',
  updateDAR: '/v1/tigo/dar/trusted/async/users',
  coordinatesOfAddress: '/v1/tigo/home/gt/activator/locations/address/coordinates',
  emailConfirm: 'v1/tigo/eventbroker/events',
  maintenance: '/v1/tigo/maintenance',
};

export const crAPI = {
  accessToken: '/v1/tigo/cr/accesstoken',
  createLead: '/v1/tigo/home/cr/activator/orders',
  getClientsById: '/v1/tigo/home/cr/activator/salesleads/accounts',
  getClientDebts: '/v1/tigo/home/cr/activator/creditScore/client',
  validateClient: '/v1/tigo/home/cr/activator/salesleads/documents',
  updateLead: '/v1/tigo/home/cr/activator/orders/:id',
  getLocations: '/v1/tigo/home/cr/activator/location/geographicAddresses',
  checkCoverage: 'v1/tigo/home/cr/activator/offers/coverage',
  upload: '/v1/tigo/cr/upload',
  validateOTP: '/v1/tigo/cr/otp/validate',
  otp: '/v1/tigo/cr/otp',
  getLeadById: 'v1/tigo/home/cr/activator/orders/leads/:id',
  getPackages: '/v1/tigo/home/cr/activator/offers',
  getAddons: '/v1/tigo/home/cr/activator/offers',
  shoppingCart: '/v1/tigo/home/cr/activator/orders',
  updateBasket: '/v1/tigo/home/cr/activator/orders',
  getInstallationDates: '/v1/tigo/home/cr/activator/field-services/dates',
  submitPaymentOrder: '/v1/tigo/pg/orders',
  createPaymentAuthToken: '/v1/tigo/pg/authtoken',
  getPaymentStatus: '/v1/tigo/pg/order/status',
  createOrUpdateDAR: '/v1/tigo/dar/trusted/async/users',
  createIngenicoSession: '/v1/tigo/ingenico/dfp',
  shortUrl: '/v1/tigo/home/cr/activator/short/url',
}

export const boAPI = {
  accessToken: '/v1/tigo/accesstoken',
  createLead: '/v1/tigo/home/bo/activator/orders',
  updateLead: '/v1/tigo/home/bo/activator/orders/basket/:id',
  confirmLead: '/v1/tigo/home/bo/activator/orders/:id',
  checkCoverage : '/v1/tigo/home/bo/activator/offers/coverage',
  otp: '/v1/tigo/otp',
  validateOtp: '/v1/tigo/otp/validate',
  upload: '/v1/tigo/upload',
  getLocations: '/v1/tigo/home/bo/activator/locations/buildings/:id',
  getLeadById: 'v1/tigo/home/bo/activator/orders/leads/:id',
  packages : 'v1/tigo/home/bo/activator/offers/locations',
  getClientDebts: 'v2/tigo/b2b/bo/billing/customers/:customerType/:identificationType/:identificationID/invoices',
  validateClient: '/v1/tigo/home/bo/activator/offers/validate',
  updateDAR: '/v1/tigo/dar/trusted/async/users',
  getAppointment: '/v1/tigo/home/bo/cases/contracts/{contractId}/appointment',
  getTimeSlots: '/v1/tigo/home/bo/cases/contracts/',
  getPaymentQR: '/v1/tigo/pg/transactions/orders/async',
  submitPaymentCard: '/v1/tigo/pg/orders',
  getPaymentStatus: '/v1/tigo/pg/order/status',
  zendeskCreate: '/v1/tigo/zendesk/tickets'
}

export const fixedRequestHeaders = {
  co: {
    documentVerification: {
      tipoId: 1,
      numeroId: 900092385,
      nit: 900092385,
      validarNombre: 1,
    },
    packages: {
      'eligibility-contexts.asset.location.type': 'socialStratus',
    },
  },
};
