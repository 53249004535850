import styled from 'styled-components';
import { appColors } from 'constants/colors';
import Dialog from '@material-ui/core/Dialog';

export const Modal = styled(Dialog)`
.MuiPaper-root {
  width: 100%;
  max-width: 707px;
}
`;

export const ModalWrp = styled.div`
  .MuiPaper-root {
    width: 100%;
    max-width: 707px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    padding: 0 18px;
    min-height: 36px;
    line-break: 36px;
  }
  .closeIcon {
    position: absolute;
    right: 17px;
    top: 17px;
    cursor: pointer;
    height: 13px;
    @media (min-width: 1024px) {
      height: 18px;
    }
  }
`;

export const Heading = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 23.44px;
  color: #00377d;
  padding-bottom: 12px;
  @media (min-width: 1024px) {
    font-size: 28px;
    line-height: 32.81px;
    padding-bottom: 19px;
  }
`;

export const Info = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: #565656;
  @media (min-width: 1024px) {
    font-size: 13px;
    line-height: 15.23px;
  }
`;

export const Link = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${appColors.secondaryCyan};
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  img {
    width: 16px;
    margin-right: 6px;
  }
`;

export const RefLinks = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #565656;
  .link {
    color: ${appColors.secondaryCyan};
    display: block;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 33px 29px 15px;
  border-bottom: 1px solid #e9e9e9;
  button:disabled,
  button[disabled] {
    border: 1px solid #f1f2f3;
    background-color: #f1f2f3;
    color: #9d9fa1;
  }
  button {
    margin: 0;
    min-width: 132px;
  }
  @media (min-width: 1024px) {
    padding-bottom: 18px;
  }
`;
export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 0px 29px;
  @media (min-width: 1024px) {
    padding-top: 16px;
  }
`;

export const InputWrp = styled.div`
  > div {
    margin: 0;
    min-width: 293px;
  }
  > div:first-child {
    padding-bottom: 15px;
  }
  padding-top: 18px;
  padding-bottom: 18px;
  @media (max-width: 361px) {
    > div {
      min-width: auto;
    }
  }
`;

export const BtnWrp = styled.div`
  position: relative;
  margin: 13px 0;
  opacity: ${({ isProcessing }) => isProcessing && '0.5'};
  .MuiCircularProgress-root {
    position: absolute;
    z-index: 10;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }
  .MuiPaper-root {
    color: #00c8ff;
  }
`;
