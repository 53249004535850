import fetchApi from "../../services/fetch";
import { svAPI } from "../../constants/api";
import { pinEncryptKeyPair } from "../../config/rsa";
const NodeRSA = require("node-rsa");
const key = new NodeRSA(pinEncryptKeyPair);

const initialState = {
  salesData: [],
  salesReport: [],
  availableDates: null,
  notifyIndexes: [],
  idModified: false
};

export const sales = {
  state: { ...initialState },
  reducers: {
    updateSalesData(state, payload) {
      return {
        ...state,
        salesData: payload,
      };
    },
    updateSalesReport(state, payload) {
      return {
        ...state,
        salesReport: payload,
      };
    },
    setAvailableDates(state, payload) {
      return {
        ...state,
        availableDates: payload,
      };
    },
    modifySalesReport(state, payload) {
      return {
        ...state,
        salesReport: {
          ...state.salesReport,
          ...payload,
        },
      };
    },
    updateNotifyIndexes(state, payload) {
      return {
        ...state,
        notifyIndexes: payload
      }
    },
    clearSales() {
      return {
        ...initialState,
      };
    },
    idModificationStatus(state, payload) {
      return {
        ...state,
        idModified: payload
      }
    }
  },

  effects: (dispatch) => {
    return {
      async getSales(sellerPin) {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "post",
          url: svAPI.sales,
          data: { PIN: key.encrypt(sellerPin, "base64") },
        })
          .then((response) => {
            dispatch.sales.updateSalesData(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            dispatch.auth.clearPIN();
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async getSalesReport(params) {
        const { leadID, pin } = params;
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "post",
          url: svAPI.salesDetail.replace(":id", leadID),
          data: { PIN: key.encrypt(pin, "base64") },
        })
          .then((response) => {
            if(response?.data?.actNode) {
              params.callback && params.callback(response?.data)

            }
            dispatch.sales.updateSalesReport(response?.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            dispatch.apiStatus.setRequestFailed({ ...error });
          });
      },
      async getScheduleInfo(data) {
        dispatch.apiStatus.setProcessing(true);
        fetchApi({
          method: "post",
          url: svAPI.scheduleDates,
          data: data,
        })
          .then((response) => {
            dispatch.sales.setAvailableDates(response.data);
            dispatch.apiStatus.setProcessing(false);
          })
          .catch((error) => {
            if (typeof error.message === "object") {
              dispatch.apiStatus.setRequestFailed({
                errorCode: error.errorCode,
                message: error.message.message,
              });
            } else {
              dispatch.apiStatus.setRequestFailed({ ...error });
            }
          });
      },
    };
  },
};
