import { pageURL_GT } from 'constants/URLs';
import { configGT } from 'config/UIConfig_GT';

export default {
    appRoutes: [
        {
            path: '/',
            component: 'Landing',
        },
        {
            path: '/redirect',
            component: 'Landing',
        },
        {
            path: pageURL_GT.maintenance,
            component: 'Maintenance'
        },
    ],
    privateAppRoutes: [
        {
            path: '/',
            component: 'HomePage',
            formData: configGT['HomePage']
        },
        {
            path: pageURL_GT.home,
            component: 'HomePage',
            formData: configGT['HomePage']
        },
        {
            path: pageURL_GT.clientInformation,
            component: 'ClientInformation',
            formData: configGT['ClientInformation']
        },
        {
            path: pageURL_GT.existingServices,
            component: 'ExistingServices',
            formData: configGT['ExistingServices']
        },
        {
            path: '/existing-debt',
            component: 'ExistingDebt',
            formData: configGT['ExistingDebt']
        },
        {
            path: pageURL_GT.locationServices,
            component: 'LocationActivation',
            formData: configGT['LocationActivation']
        },
        {
            path: pageURL_GT.inputAddress,
            component: 'InputAddress',
            formData: configGT['InputAddress']
        },
        {
            path: pageURL_GT.addressList,
            component: 'AddressList',
            formData: configGT['AddressList']
        },
        {
            path: pageURL_GT.map,
            component: 'LocationService',
            formData: configGT['LocationService']
        },
        {
            path: pageURL_GT.idScanFront,
            component: 'IDFront',
            formData: configGT['IDFront']
        },
        {
            path: pageURL_GT.idScanRear,
            component: 'IDRear',
            formData: configGT['IDRear']
        },
        {
            path: pageURL_GT.idReview,
            component: 'IDReview',
            formData: configGT['IDReview']
        },
        {
            path: pageURL_GT.billUpload,
            component: 'BillUpload',
            formData: configGT['BillUpload']
        },
        {
            path: pageURL_GT.billConfirm,
            component: 'BillConfirm',
            formData: configGT['BillConfirm']
        },
        {
            path: pageURL_GT.documentUpload,
            component: 'DocumentUpload',
            formData: configGT['DocumentUpload']
        },
        {
            path: pageURL_GT.offerNotAvailable,
            component: 'NoOffer',
            formData: configGT['NoOffer']
        },
        {
            path: pageURL_GT.products,
            component: 'ProductSelection',
            formData: configGT['ProductSelection']
        },
        {
            path: pageURL_GT.addons,
            component: 'Addons',
            formData: configGT['Addons']
        },
        {
            path: pageURL_GT.checkout,
            component: 'Checkout',
            formData: configGT['Checkout']
        },
        {
            path: pageURL_GT.contractInfo,
            component: 'ContractInfo',
            formData: configGT['ContractInfo']
        },
        {
            path: pageURL_GT.verifyPhone,
            component: 'VerifyOTP',
            formData: configGT['VerifyPhone']
        },
        {
            path: pageURL_GT.verifyEmail,
            component: 'VerifyOTP',
            formData: configGT['VerifyEmail']
        },
        {
            path: pageURL_GT.termsConditions,
            component: 'TermsAndConditions',
            formData: configGT['TermsAndConditions']
        },
        {
            path: pageURL_GT.contractSignatureInfo,
            component: 'ContractSignatureInfo',
            formData: configGT['ContractSignatureInfo']
        },
        {
            path: pageURL_GT.signatureClient,
            component: 'Signature',
            formData: configGT['SignatureClient']
        },
        {
            path: pageURL_GT.paymentReceipt,
            component: 'PaymentReceipt',
            formData: configGT['PaymentReceipt']
        },
        {
            path: pageURL_GT.scheduleVisit,
            component: 'ScheduleOrder',
            formData: configGT['ScheduleVisit']
        },
        {
            path: pageURL_GT.activation,
            component: 'Activation',
            formData: configGT['Activation']
        },
        {
            path: pageURL_GT.orderSuccess,
            component: 'OrderSuccess',
            formData: configGT['OrderSuccess']
        },
        {
            path: pageURL_GT.orderFailed,
            component: 'OrderFailed',
            formData: configGT['OrderFailed']
        },
        {
            path: pageURL_GT.salesReport,
            component: 'SalesReport',
            formData: configGT['SalesReport']
        },
        {
            path: pageURL_GT.existingServicesModify,
            component: 'ModifyExistingService',
            formData: configGT['ModifyExistingService']
        },
        {
            path: pageURL_GT.updateAddons,
            component: 'UpdateAddons',
            formData: configGT['UpdateAddons']
        },
        {
            path: pageURL_GT.confirmOrder,
            component: 'ConfirmOrder',
            formData: configGT['ConfirmOrder']
        },
        {
            path: pageURL_GT.updatedOrderSuccess,
            component: 'OrderSuccess',
            formData: configGT['OrderSuccess']
        },
        {
            path: pageURL_GT.changePlan,
            component: 'ChangePlan',
            formData: configGT['ChangePlan']
        },
    ]
}