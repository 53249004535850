export const GT_DEPARTMENTS = [
    {
        "CODIGO_DEPTO": "1",
        "state": "GUATEMALA"
    },
    {
        "CODIGO_DEPTO": "2",
        "state": "EL PROGRESO"
    },
    {
        "CODIGO_DEPTO": "3",
        "state": "SACATEPEQUEZ"
    },
    {
        "CODIGO_DEPTO": "4",
        "state": "CHIMALTENANGO"
    },
    {
        "CODIGO_DEPTO": "5",
        "state": "ESCUINTLA"
    },
    {
        "CODIGO_DEPTO": "6",
        "state": "SANTA ROSA"
    },
    {
        "CODIGO_DEPTO": "7",
        "state": "SOLOLA"
    },
    {
        "CODIGO_DEPTO": "8",
        "state": "TOTONICAPAN"
    },
    {
        "CODIGO_DEPTO": "9",
        "state": "QUETZALTENANGO"
    },
    {
        "CODIGO_DEPTO": "10",
        "state": "SUCHITEPEQUEZ"
    },
    {
        "CODIGO_DEPTO": "11",
        "state": "RETALHULEU"
    },
    {
        "CODIGO_DEPTO": "12",
        "state": "SAN MARCOS"
    },
    {
        "CODIGO_DEPTO": "13",
        "state": "HUEHUETENANGO"
    },
    {
        "CODIGO_DEPTO": "14",
        "state": "QUICHE"
    },
    {
        "CODIGO_DEPTO": "15",
        "state": "BAJA VERAPAZ"
    },
    {
        "CODIGO_DEPTO": "16",
        "state": "ALTA VERAPAZ"
    },
    {
        "CODIGO_DEPTO": "17",
        "state": "PETEN"
    },
    {
        "CODIGO_DEPTO": "18",
        "state": "IZABAL"
    },
    {
        "CODIGO_DEPTO": "19",
        "state": "ZACAPA"
    },
    {
        "CODIGO_DEPTO": "20",
        "state": "CHIQUIMULA"
    },
    {
        "CODIGO_DEPTO": "21",
        "state": "JALAPA"
    },
    {
        "CODIGO_DEPTO": "22",
        "state": "JUTIAPA"
    }
]